<template>
<div class="order-table">
    <div class="switchOrder">
        <div :class="{activeOrder:isActive}" @click="isActive=true">سفارشات فعال</div>
        <div @click="isActive=false" :class="{activeOrder:!isActive}">تاریخچه سفارشات</div>
    </div>
    <div class="table">
        <table>
            <tr class="table-header">
                <td class="resp-3">نوع سفارش</td>
                <td>رمزارز</td>
                <td class="resp-3">قیمت واحد <span class="tag">({{$coins[tradeTo].persianName}})</span></td>
                <td> مقدار</td>
                <td class="resp-1">قیمت کل <span class="tag">({{$coins[tradeTo].persianName}})</span></td>
                <td class="resp-2"> زمان</td>
                <td> وضعیت</td>
            </tr>
            <tr class="table-row" v-for="(order,index) in dataSet.filter(order=> isActive==(order.orderStatus==='IS_OPEN') )" :key="index">
                <td class="resp-3" :style="{color:(order.orderType.includes('BUY'))?'#0B8710':'#D62B2B'}">
                    {{dict[order.orderType.split('_')[0]]+' - '+dict[order.orderType.split('_')[1]]}}
                </td>
                <td>{{$coins[tradeFrom].persianName+' - '+$coins[tradeTo].persianName}}</td>
                <td class="resp-3">{{$toLocal(order.unitPrice,0)}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-1">{{$toLocal(order.wholePrice,0)}}</td>
                <td class="resp-2">{{$G2J(order.createdAtDateTime)}}</td>
                <td align="center">
                    <span class="condition" v-if='isActive'>{{order.executedPercent}}%<img class="delete-icon" @click="cancelOrderModal(order)" src="../../../../assets/auth/trash.svg" /></span>
                    <span v-else class="condition"><img  :src="require('../../../../assets/icons/'+statuses[order.orderStatus]+'.svg')"></span>
                    <!-- <span>{{order.orderStatus}}</span> -->
                </td>
            </tr>
        </table>
    </div>
        <DeleteOrder v-if="modalType=='CANCEL'" @modalToggle="value=>modalType=value" @confirm="removeorder()"/>
    
</div>
</template>

<script>
import DeleteOrder from '../../../modals/DeleteOrder'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";

export default {
    name: 'History',
    components:{
        DeleteOrder
    },
    data() {
        return {
            modalType:'',
            removeid:'',
            isActive: true,
            dataSet: [],
            dict: {
                'LIMITED': 'حد',
                'MARKET': 'بازار',
                'EXCHANGE': 'صرافی',
                'BUY': 'خرید',
                'SELL': 'فروش'
            },
            
        statuses:{
        FINISHED: 'success',
        IS_OPEN: 'باز',
        CANCELLED_BY_ADMIN: 'fail',
        CANCELLED_BY_USER: 'fail',
        EXPIRED: 'fail',
        RAISED_BY_USER: 'success',
        PENDING: 'success',
        ACCEPTED: 'success',
        DONE:' success',
        FAILED:'fail',
        FINISHED_WITHOUT_TRANSACTION:'success'
    }

        }
    },
    computed: {
        tradeTo,
        tradeFrom,

    },
    methods: {

        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },
        cancelOrderModal(e) {
            this.loop.stop()
            this.modalType = 'CANCEL'
            this.removeid = e.id
        },
        tradeType(type) {
            return type.includes('EXCHANGE') ? 'صرافی' : type.includes('MARKET') ? 'بازار' : 'حد'
        },
        async getLastOrders() {
            // if (this.page <= this.lastPage) {
            this.page++
            let res = await this.$axios.get('/orders', {
                params: {
                    market: this.tradeFrom + '_' + this.tradeTo,
                    size: 20
                }
            })
            if (res.content)
                this.dataSet = res.content;
            this.loading = false
        }

        // },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 10000, 'lastOrder')
            this.loop.start()
        } else {
            this.getLastOrders()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.table-row {
    height: 30px;
}

.table-header {
    background: rgba(37, 67, 130, 0.06);
    height: 30px;
    z-index: 10;
    position: sticky;
    top:0;
    opacity: 0.7;
}

.tag {
    opacity: 0.6;
}

.table {
    overflow-y: auto;
    max-height: 182px;
}

.order-table {
    font-size: clamp(13px, 1.2vw, 14px);
    display: flex;
    white-space: nowrap;
    flex-direction: column;

}

.switchOrder>div {
    display: flex;
    flex-grow: 1;
    opacity: 0.7;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.delete-icon{
    cursor: pointer;
}
.switchOrder {
    display: flex;
    background: rgba(136, 136, 136, 0.05);
    backdrop-filter: blur(15px);
    color: #EFEFEF;
    padding: 2px;
    height: 30px;
    max-width: 232px;
    width: 100%;
}

table {
    background: rgba(136, 136, 136, 0.05);
    width: 100%;
    color: white;

}
td:first-child{
    text-align: right;
    padding-right: 11px;
}
.activeOrder {
    opacity: 1 !important;
    box-sizing: border-box;
    border-radius: 2px;
}

@media(max-width: 820px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width: 620px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width: 430px) {
    .resp-3 {
        display: none;
    }
}
</style>
