<template>
<div class="modal">

    <Modal @close="$emit('modalToggle','')">
        <template #header>
            <div>لغو سفارش </div>
        </template>
        <template #body>
            <div>
                <div class="opac-point">
                    <img class="caution-icon" src='../../assets/auth/caution.svg' />
                    <div>آیا از لغو سفارش مطمئنید؟</div>
                </div>

            </div>

        </template>
        <template #footer>
            <div class="btns">
                <button class="modal-confirm-btn cancel-btn" @click="$emit('modalToggle','')">خیر</button>
                <button class="modal-confirm-btn confirm-btn" @click="$emit('confirm')">بله</button>
            </div>
        </template>

    </Modal>

</div>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'CryptoSell',
    components: {
        Modal,
    },

    props: {
        order: Object
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal {
    max-width: 444px;
}

.confirm-btn {
    opacity: 1 !important;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;

}

.btns {
    color: #EFEFEF;
    margin:auto;
    margin-bottom: 20px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    height: 41px;
    margin-top: 41px;
    max-width: 180px;
  
    column-gap: 40px;

    border-radius: 2px;
}

.cancel-btn {
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    vertical-align: middle;
    color: #FF951F;
 
 
    background: rgba(30, 35, 46, 0.6);

    opacity: 0.7;
    font-style: normal;
}

.opac-container {
    background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.opac-point {
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
    font-size: clamp(12px, 1.3vw, 14px);
    margin-bottom: 8px;
}

.detail {
    display: flex;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #EFEFEF;
}

.tag {
    opacity: 0.7;
}

p {
    font-size: clamp(12px, 1.3vw, 14px);
}
</style>
