<template>
<div class="header">

    <div class="h1">ویستا محیطی امن و مطمئن برای معاملات شما</div>
    <div class="h2">انجام معاملات خرید و فروش خود را به ما بسپارید</div>

    <div class="row1">
        <div :class="{ active: dealType=='BUY', btn: dealType!='BUY' }" v-on:click="dealType='BUY'"> خرید</div>
        <div :class="{ active: dealType=='SELL', btn: dealType!='SELL' }" v-on:click="dealType='SELL'"> فروش</div>
    </div>
    <div class="traderequest">
        <div><label>انتخاب ارز</label>
            <!-- <div class="crypto-input"><img class="arrow" src="../../assets/auth/yellow-arrow.png" /><input :value='$coins[coin].persianName' disabled='true'/></div> -->
            <div class="drop-container">
                <div class="dropdown">
                    <div class="drop-title" @click="dropOpen=!dropOpen">

                        {{choice.label}}
                        <img :class="{icon:true}" @click="dropOpen=!dropOpen" :src="require('../../assets/auth/yellow-arrow.png')" />
                    </div>
                    <div class="items" :style="{height:(dropOpen)?filterItems().length*32+'px':'0px'}">
                        <div class="field" v-for="(item,index) in filterItems()" :key="index" @click="choose(item)">
                            {{item.label}}
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div><label>مقدار </label><input v-model="amount" @input="amount=$toLocal(amount,$decimal[coin.destCoinSymbol])" /></div>
        <div><label>قیمت واحد </label><input :disabled="true" :value="$toLocal(unitPrice,0)" /></div>
        <div><label>مجموع</label><input :value="sum" :disabled="true" /></div>
    </div>
    <div class="tradebtns">
        <button class="buyrequest" @click="state.userInfo.authorized?checkModal():$router.push('/Auth/Login') " :disabled="disabled">درخواست {{dealType=='BUY'?'خرید':'فروش'}}</button>
    </div>
    <CryptoSell @confirm="sendData()" v-if="modalType=='SELL'" @modalToggle="value=>modalType=value" :order='data' />
    <CryptoBuy @confirm="sendData()" v-if="modalType=='BUY'" @modalToggle="value=>modalType=value" :order="data" />
    <OrderDetails v-if="modalType=='success'" @modalToggle="value=>modalType=value" :order="Object.assign({type:dealType},temptData)" />
  
</div>
</template>

<script>
import CryptoBuy from '../modals/CryptoBuy'
import CryptoSell from '../modals/CryptoSell'
import OrderDetails from '../modals/OrderDetails'
import {
    Loop,

} from "@/library/reuseableFunction";

export default {
    name: 'Header',
    components: {

        CryptoSell,
        CryptoBuy,
        OrderDetails,
    },
    data() {
        return {
            coinsDetails: [],
            wallets: [],
            dealType: 'BUY',
            dropOpen: false,
            choice: {
                label: 'بیتکوین',
                value: 'BITCOIN'
            },
            cryptos: [],
            coin: {},
            modalType: '',
            amount: 0,
            data: {}

        }
    },
    computed: {
        sum(){
            return this.$toLocal(this.$S2N(this.amount)*this.$S2N(this.unitPrice),0)
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY') ||
                (this.$S2N(this.dealType == 'BUY' ? this.$S2N(this.sum) : this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.coin.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.coin.minAmountExchange))
        },
          tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return this.$toLocal(temp.credit, 0)
            }
            return 0
        },
        credit() {
            if (this.state.userInfo.authorized)
                return (this.dealType == 'BUY') ? this.tomanBalance :
                    this.$toLocal(this.coin.credit, this.$decimal[this.coin.destCoinSymbol])
            else
                return 0
        },
        unitPrice() {
            if (this.coin)
                return this.dealType == 'BUY' ? this.coin.buyPrice : this.coin.sellPrice
            else
                return 0
        },
        coins() {

            return Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].persianName,
                    value: coin[0]
                }
            })
        },
    },
    methods: {

        async getCoinsPrice() {
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response)
            // this.coin.buyPrice = this.cryptos.find(coin => coin.destCoinSymbol.includes(this.choice.value)).buyPrice
            // this.coin.sellPrice = this.cryptos.find(coin => coin.destCoinSymbol.includes(this.choice.value)).sellPrice
        },

        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },
        async getCoinsDetails() {
            this.state.loading = true
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            // if (this.cryptos) {
            //     if (this.wallets && this.coinsDetails)
            //         this.setCrypto(this.cryptos[0])
            // }
        },

        setCrypto() {
            this.coin = this.cryptos.find(coin => coin.destCoinSymbol.includes(this.choice.value))
            this.coin.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.coin.destCoinSymbol).minAmountExchange
            this.coin.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.coin.destCoinSymbol).maxAmountExchange
            if (this.state.userInfo.authorized)
                this.coin.credit = this.wallets[this.coin.destCoinSymbol].credit
            this.coin.nets = this.coinsDetails.find(coin => coin.coin == this.coin.destCoinSymbol).tokenTypeDetails
            this.net = this.coin.nets[0].tokenType
            this.coin.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.coin.destCoinSymbol).exchangeFee
        },
        checkModal() {
            this.data.amount = this.amount
            this.data.marketType = this.coin.destCoinSymbol + '_' + 'TOMAN'
            this.data.destinationCoin = this.coin.destCoinSymbol
            this.data.tokenType = this.net
            this.data.totalPrice = this.sum
            this.data.unitPrice = this.unitPrice
            this.data.orderType = (this.dealType == 'BUY') ? 'EXCHANGE_BUY' : 'EXCHANGE_SELL'
            if (this.wallet == 'OTHER' && this.dealType == 'BUY')
                this.data.destinationWallet = this.walletAddress
            this.modalType = this.dealType
        },
        async sendData() {
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
            this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice)
            this.modalType = ''
            if (this.dealType == 'BUY') {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )

                this.modalType = 'success'

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.modalType = 'success'

                this.state.loading = false
            }

        },
        async update() {
            let tempCoin
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => tempCoin = response.find(coin => coin.destCoinSymbol == this.coin.destCoinSymbol))

            if (tempCoin) {
                this.coin.buyPrice = tempCoin.buyPrice
                this.coin.sellPrice = tempCoin.sellPrice

            }

        },
        filterItems() {
            return this.coins.filter(item => {
                if (item.value != this.choice.value) {
                    return item
                }
            })

        },

        choose(item) {
            this.dropOpen = false;
            this.choice = JSON.parse(JSON.stringify(item))
            this.setCrypto()

        }
    },
    async mounted() {

        if (this.state.loop) {
            this.loop = new Loop(this.getCoinsPrice, this.state.time, 'getPrice')
            this.loop.start()
        } else {
            this.getCoinsPrice()
        }
        await this.getCoinsDetails()
        if (this.state.userInfo.authorized)
            await this.getWallets()
        this.setCrypto()

    },
    beforeDestroy() {
        this.loop.stop()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown {
    position: absolute;
    white-space: nowrap !important;
    width: 100%;
    border-radius: 2px;
    backdrop-filter: blur(15px);

    background: rgba(136, 136, 136, 0.05);
    z-index: 3;
    cursor: pointer;
}

.drop-open {
    transform: rotate(180deg);
}

.items {
    height: 0px;
    transition: height 0.6s ease-out;
    cursor: pointer;
    padding: 0px 16px;

    background: #252A3B;
    max-height: 100px;
    overflow-y: scroll;
}

label {
    margin-bottom: 6px;
}

.drop-container {
    position: relative;
    max-height: 41px;
    height: 41px;
    flex: 1;

    min-width: 110px;
    z-index: 3;
    flex-basis: 80px;
    color: #EFEFEF;
}

.field {
    height: 32px;
    font-size: clamp(11px, 1.2vw, 12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;

}

.drop-title {
    height: 41px;
    font-size: clamp(11px, 1.2vw, 12px);
    background: rgba(255, 255, 255, 0.05);

    display: flex;
    padding: 0px 16px;
    align-items: center;
    justify-content: space-between;
    column-gap: 11px;

}

.icon {
    cursor: pointer;

}

.active {
    opacity: 1 !important;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;

}

.row1 {
    color: #EFEFEF;
    display: flex;
    cursor: pointer;
    height: 41px;
    margin-top: 41px;
    max-width: 180px;
    /* background: rgba(136, 136, 136, 0.05); */
    background: rgba(30, 35, 46, 0.6);
    border-radius: 2px;
}

.btn {
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    vertical-align: middle;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    opacity: 0.7;
    font-style: normal;
}

.traderequest {
    display: flex;
    row-gap: 15px;
    flex-wrap: wrap;
    column-gap: 16px;
    margin-top: 21px;
    justify-content: center;
}

input {
    height: 41px;
    background: rgba(255, 255, 255, 0.05);

    backdrop-filter: blur(15px);

    border-radius: 2px;
    flex-grow: 1;
    color: white;
    width: 100%;

}

.traderequest>div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.h1 {
    font-size: clamp(21px, 1.8vw, 24px);
    font-weight: 500;
}

.h2 {
    font-size: clamp(17px, 1.4vw, 18px);
    font-weight: 500;
    margin-top: 16px;
}

.crypto-input {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
}

.arrow {
    position: absolute;
    padding-left: 10px;
    z-index: 1;

}

.header {
    text-align: right;
    display: flex;
    width: 100%;
    max-width: 791px;
    flex-direction: column;
    /* align-items: center; */
}

.tradebtns {
    display: flex;
    flex: 1;
    margin: auto;
    justify-content: center;
    column-gap: 18px;
    margin-top: 32px;
    max-width: 400px;
}

.header>* {
    width: 100%;
}

.tradebtns>button {
    flex-grow: 1;
    height: 41px;
    border-radius: 2px;
}

.buyrequest {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    max-width: 200px;
}

.sellrequest {

    color: #F7BD68;
    background: rgba(136, 136, 136, 0.05);

}
</style>
