import Vue from 'vue'
import App from './App.vue'
import './library/states.js'
import router from './library/router'
import VueRouter from 'vue-router'
import './style/index.css'
import {InlineSvgPlugin} from 'vue-inline-svg';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import './library/axios'
import './library/globalFunctions'
import './library/globalConstants'
import './library/georgianToJalali'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import LabelInput from './components/public/LabelInput'
import CardHeader from './components/public/CardHeader'
import CardButton from './components/public/CardButton'
import Card from './components/public/Card'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

Vue.component('LabelInput',LabelInput)
Vue.component('CardHeader',CardHeader)
Vue.component('CardButton',CardButton)
Vue.component('Card',Card)
Vue.use(VuePersianDatetimePicker, {
  name: 'date-picker',
  props: {
    format:"jYYYY jMMMM jDD",
    displayFormat: 'jYYYY-jMMMM-jDD ', // if you need jalali dates
    color: '#252A3B',
    autoSubmit: true,
    }
})
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(PerfectScrollbar)
Vue.prototype.$cryptos=[
  {title:"بیتکوین",symbol:"BTC",icon:"bitcoin",price:160000,value:0.514,amount:32325,change:6.4,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
  {title:"اتریوم",symbol:"ETH",icon:"etherium",price:800000,value:0.684,amount:32325,change:2.1,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
  {title:"تتر",symbol:"USDT",icon:"tether",price:1344000,value:0.218,amount:32325,change:-3.4,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
  {title:"دوج کوین",symbol:"DOGE",icon:"doge",price:400000,value:8.14,amount:32325,change:6.4,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
  {title:"ترون",symbol:"TRX",icon:"throne",price:233000,value:12.014,amount:32325,change:-6.4,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
  {title:"لایت کوین",symbol:"LTC",icon:"litecoin",price:233000,value:12.014,amount:32325,change:-6.4,changeT:8.4,buyPrice:336729432,sellPrice:336729432,currentPrice:336729432,buyPriceT:346,sellPriceT:346,currentPriceT:346,chart:"",},
      
    ]
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueSweetalert2);


Vue.use(InlineSvgPlugin);


new Vue({
  router,
 
  render: h => h(App),
}).$mount('#app')
