<template>
<div :class="{switch:true,dark:theme==1,light:theme==2}">
    <div :class="{active:choosed==item.value}" v-for="(item,index) in items " :key="index" @click="choice=item.value;$emit('choose',item.value)" >
     {{item.label}}
    </div>
</div>
  
</template>

<script>
export default {
  name: 'SwitchComp',
  props:{
      items:{type:Array},
      choosed:{type:String},
      theme:{type:Number}
  },
  data(){
      return {
          choice:this.choosed
      }
  }
 
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    width: 100%;
  
    display: flex;
    font-size: clamp(12px,1vw,14px);
    flex-wrap: wrap;
    padding: 2px;
    color: #EFEFEF;
    background: rgba(136, 136, 136, 0.05);
}
.switch > div{
      flex: 1;
      height: 35px;
      cursor: pointer;
      display: flex;
      flex-basis: 100px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
    
}

 .active{
   border: 1px solid #F29A39;
   opacity: 1!important;


}
</style>
