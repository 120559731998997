<template>
<div  class="table-container">
  <div class="table-title">تاریخچه ورود   </div>
    <table class="table">
        <tr class="table-header">
            <td class="resp-2">آی پی</td>
            <td>نوع دستگاه</td>
            <td class="resp-3">پلتفرم</td>
            <td class="resp-1">نوع مرورگر</td>
            <td  class="resp-3">زمان</td>
            <td>وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(login,index) in logins" :key="index">
                <td class="ip resp-2">{{login.ipAddress}}</td>
                <td>{{login.deviceType}}</td>
                <td class="resp-3">{{login.osType}}</td>
                <td class="resp-1">{{login.browser}}</td>
                <td class="resp-3">{{$G2J(login.loginRequestedDateTime)}}</td>
                <td >
                    <img :src="require(`../../../assets/icons/${login.isSuccessful?'success':'fail'}.svg`)"/>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</template>

<script>
export default {
  name: 'Logins',
  props: {
   
  },
  methods:{
     async check() {
                // console.log(this.page,this.lastPage);
                // if (this.page<=this.lastPage){ //for scroll loading
                    // this.page ++ //for scroll loading
                    const r = await this.$axios("/users/account?imageNeeded=false")
                     this.verifyMethod = r.baseDTO.towStepAuthenticationType
                     console.log(this.verifyMethod)
                    this.state.loading = true
                    const res = await this.$axios.get('/users/login-history', {
                        params:{
                            size : 10,
                            page : this.page
                        }
                    })
                    this.lastPage = res.totalPages
                    this.logins = [...this.logins, ...res.content]
                  
                   
                    
                // }
            },
            load() {
                if(this.page<=this.lastPage){
                    setTimeout(() => {
                    this.check()
                }, 1000)}
            }
  },
   mounted() {
            this.check()
        },
    
  data(){
    return{
         deviceDict:{"MOBILE":"موبایل","DESKTOP":"دسکتاپ"},
         logins:[],
    
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


 @media(max-width:1200px){
   
    .resp-1{
        display: none;
    } 
}
@media(max-width:900px){
    .resp-2{
        display: none;
    } 
}

@media(max-width:500px){
    .resp-3{
        display: none;
    }
}
</style>
