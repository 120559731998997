<template>
<div>
    <diV>
        <div class="head">
            <div />نظرات کاربران
            <div />
        </div>
    </diV>
    <hooper class="carousel" :infiniteScroll="true" :progress="true" :autoPlay="true" :playSpeed="1500" :rtl="true" :settings="hooperSettings">
        <slide v-for="(post,index) in posts " :key='index'>
            <div class="container">
                <div class="post">
                    <div class="post-header" :style="{'backgroundImage': 'url('+post.yoast_head_json.og_image[0].url+')'}">
                        <div class="category">کیف پول</div>
                    </div>
                    <div class="post-body">
                        <a :href="post.link" class="post-title"> {{post.title.rendered}} </a>
                        <div class="post-text" v-html="post.excerpt.rendered.slice(0,70)+' ...'"> </div>
                        <br>
                        <div class="post-footer">
                            <div><img src='../../assets/post/eye.svg' />{{$G2J(post.date)}}</div>
                            <div class="score">
                                <div>۳.۵</div><img src='../../assets/post/star.svg' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </slide>
        <hooper-navigation slot="hooper-addons">
            <svg slot="hooper-next" width="16" height="28" viewBox="0 0 16 28" fill="" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5853 0.585424C14.9603 0.96048 15.1709 1.4691 15.1709 1.99943C15.1709 2.52975 14.9603 3.03837 14.5853 3.41343L5.41332 12.5854C5.04834 12.9657 4.84454 13.4724 4.84454 13.9994C4.84454 14.5265 5.04834 15.0332 5.41332 15.4134L14.5853 24.5854C14.9496 24.9626 15.1512 25.4678 15.1467 25.9922C15.1421 26.5166 14.9318 27.0183 14.561 27.3891C14.1901 27.7599 13.6885 27.9702 13.1641 27.9748C12.6397 27.9793 12.1345 27.7777 11.7573 27.4134L2.58532 18.2434C2.02806 17.6863 1.58601 17.0248 1.28442 16.2968C0.98283 15.5687 0.827602 14.7884 0.827602 14.0004C0.827602 13.2124 0.98283 12.4321 1.28442 11.7041C1.58601 10.9761 2.02806 10.3146 2.58532 9.75743L11.7573 0.585424C12.1324 0.210484 12.641 -0.000146866 13.1713 -0.000146866C13.7017 -0.000146866 14.2103 0.210484 14.5853 0.585424Z" fill="white" />
            </svg>

            <svg slot="hooper-prev" width="16" height="28" viewBox="0 0 16 28" fill="yellow" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.41467 0.585424C1.03973 0.96048 0.829102 1.4691 0.829102 1.99943C0.829102 2.52975 1.03973 3.03837 1.41467 3.41343L10.5867 12.5854C10.9517 12.9657 11.1555 13.4724 11.1555 13.9994C11.1555 14.5265 10.9517 15.0332 10.5867 15.4134L1.41467 24.5854C1.05036 24.9626 0.848769 25.4678 0.853326 25.9922C0.857883 26.5166 1.06822 27.0183 1.43904 27.3891C1.80985 27.7599 2.31148 27.9702 2.83587 27.9748C3.36027 27.9793 3.86547 27.7777 4.24268 27.4134L13.4147 18.2434C13.9719 17.6863 14.414 17.0248 14.7156 16.2968C15.0172 15.5687 15.1724 14.7884 15.1724 14.0004C15.1724 13.2124 15.0172 12.4321 14.7156 11.7041C14.414 10.9761 13.9719 10.3146 13.4147 9.75743L4.24268 0.585424C3.86762 0.210484 3.359 -0.000146866 2.82867 -0.000146866C2.29835 -0.000146866 1.78973 0.210484 1.41467 0.585424Z" fill="white" />
            </svg>

        </hooper-navigation>

    </hooper>
</div>
</template>

<script>
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'WeblogContents',
    components: {
        Hooper,
        Slide,
        HooperNavigation

    },
    data() {
        return {
            news: [],
            posts: [],
            hooperSettings: {
                itemsToShow: 2,
                centerMode: false,
                breakpoints: {
                    200: {
                        itemsToShow: 1
                    },
                    650: {
                        centerMode: true,
                        itemsToShow: 2
                    },
                    1000: {

                        itemsToShow: 3,

                    },
                    1400: {
                        itemsToShow: 4,

                    }
                }
            }
        }
    },
    methods: {
        async getNews() {
            await fetch('https://aviinex.com/blog/wp-json/wp/v2/posts?per_page=8&context=embed&page=1')
                .then(response => response.json()).then(data => this.posts = data);
        }
    },
    mounted() {
        this.getNews()
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.carousel {
    display: flex;
    align-items: center;
    height: 351px;

    z-index: 5;
    box-sizing: border-box;
}

.container {
    padding: 0px 10px 0px 10px;
    height: 100%;

}

.post-details {
    color: #F29A39;
    font-size: 14px;
}

.post-text {

    opacity: 0.7;
    font-size: 14px;
    font-weight: 400;
}

.head {
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px, 1.7vw, 20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.head>div {
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}

.post-title {
    font-size: 16px;
    cursor: pointer;
    color: #EFEFEF;
    margin-top:13px;

    font-weight: 500;
}

.post-body {
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
}

.post {

    color: white;
    height: 100%;
    font-style: normal;
    width: 100%;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 5px;
    ;
}

.post-header {
    position: relative;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;

}

.post-footer {
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    color: #F29A39;
}

.post-footer>div {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding-bottom: 6px;
}

.score {
    align-items: center;
    display: flex;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    color: #F29A39;
}

.category {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 30px;
    width: 89px;
    right: 0;
    font-size: 16px;
    font-weight: 400;
    background: rgba(37, 67, 130, 0.06);
    backdrop-filter: blur(15px);
    border-radius: 5px;

}

.img {
    width: 100%;
    object-fit: cover;

}
</style>
