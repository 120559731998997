<template>
<div class="modal">
    <Modal @close="$emit('modalToggle','')">
        <template #header>
            واریز ریالی
        </template>
        <template #body>
            <div>
                <p>جهت افزایش اعتبار کیف پول ریالی خود با استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایل‌تان ثبت و تایید شده است، استفاده نمایید.</p>
                <label-input @value="value=>amount =value" :theme=1 type="number" label="مبلغ (تومان)" />
                <div class="card-select">
                    <LabelInput @value='value=>regex=value' :value="walletAddress" label=" شماره کارت" @click="show=!show" :disabled="true" />
                    <div class="cards-drop" v-if="show" :style="{height:cardFilter.length*30+'px'}">
                        <div class="card-row" v-for="(card,index) in cardFilter" :key="index" @click="chooseCard(card.cardNumber)">
                            {{card.cardNumber}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <CardButton :disabled="!amount || !walletAddress || ($S2N(amount) < 1000)" @clicked="deposit">انتقال به درگاه پرداخت</CardButton>

        </template>

    </Modal>
</div>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'RialDepsoit',
    components: {
        Modal,
    },
    data() {
        return {
            amount: '',
            walletAddress: '',
            banks: [],
            regex: '',
            show: false

        }
    },
    computed: {
        cardFilter() {
            if (this.banks)
                return this.banks.filter(card => card.cardNumber.replace('-', '').startsWith(this.regex))
            else
                return []
        }
    },
    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show = false
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
        },
        async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZARRINPAL',
                cardNumber: this.walletAddress,
                callBackURL: this.$callBackURL
            }
            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)
            if (res.message === "Deposit Request Created Successfully") {
                this.$emit('close');
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },
    },
    async mounted() {
        await this.getData()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal {
    max-width: 486px;
}

.disabled{
    opacity: 0.7;
}

.card-select {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
}

.card-row {
    padding: 5px 10px;

}

.cards-drop {
    margin-top: -6px;
    width: 100%;
    background: rgba(136, 136, 136, 0.05);
    cursor: pointer;
    color: #EFEFEF;
    font-size: clamp(13px, 1.4vw, 14px);
    max-height: 50px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
}
</style>
