<template>
  <div class="steps">
       <diV> <div class="head"><div/>مراحل انجام کار <div/></div></diV>
      <div  v-for="(step,index) in steps" :key="index" :class="{'step-container':true,ltr:index%2==1,rtl:index%2==0}">
          <div class="step">
               
                  <div :class="{circle:true,'circle-rtl':index%2==0,'circle-ltr':index%2==1,}">
                      <img class="icon" :src="require('../../assets/auth/'+step.icon+'.svg')"/>
                      </div> 
             
          <div class="text">
               <div class="title">{{step.title}}</div>
                <div class="passage">{{step.text}}</div>
          </div>

          </div>
         
         

      </div>
  </div>
</template>

<script>
export default {
  name: 'WorkSteps',
  data(){
      return{
          steps:[
              {title:"ثبت نام در سایت",text:"باداشتن تنها آدرس ایمیل میتوانید در سایت ویستا ثبت نام اولیه انجام دهید و با امکانات و قابلیت های سایت آشنا شوید.",icon:"log"},
              {title:"احرازهویت",text:"پس از ثبت نام اولیه با وارد کردن اطلاعات فردی و اطلاعات بانکی خود و همچنین یک تصویر از مدارک خود می‌توانید به سادگی و در کمترین زمان به تمامی امکانات سایت دسترسی پیدا کنید و معاملات خود را انجام دهید",icon:"fingerprint"},
              {title:"شارژ کیف پول",text:"پس از احراز هویت در سایت به تمامی امکانات سایت دسترسی خواهید داشت و باشارژ کیف پول ریالی خود می‌توانید شروع به معامله کنید.",icon:"wallet-charge"},
              {title:"شروع معامله",text:"ویستا با پشتیبانی از ۰۳ ارز متفاوت این امکان را به شما کاربران عزیز می دهد تا با کمترین محدودیت معاملات خود را انجام دهید. ",icon:"gear"},
          ]
      }
  }
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.step{
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    width: 100% ;
   
   
    flex-wrap: wrap;
    max-width:700px;
}
.step-container{
  display: flex;
     justify-content: flex-start;
   
     width: 100%;
}
.ltr{
    direction: ltr;
}

img{
    width: 100%;
   
    height: auto;
}
.rtl{
    
    direction: rtl;
  
}

.title{
    font-size: clamp(14px,1.5vw,18px);
    font-weight: 500;
    padding-bottom:9px;
    color: white;
}
.text{
    padding-right:20px;
    padding-left: 26px;
    padding-bottom:4px;
   
    padding-top:4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.passage{
    font-size: clamp(12px,1.3vw,14px);
    font-weight:400;
    padding-right: 12px;
    padding-bottom: 5px;;
    max-width: 510px;
    color: rgba(255, 255, 255, 0.7);
}

.steps{
    display: flex;
    flex-direction: column;
     padding-right:clamp(40px,4vw,60px);
    padding-left:clamp(40px,4vw,60px);
    row-gap: 32px;
    
}

.circle-rtl{
  margin-right:clamp(-60px,-4vw,-40px);
}

.circle-ltr{
 margin-left:clamp(-60px,-4vw,-40px);
}
.icon{
    width: 70%;
    height: 70%;
}
.circle{
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    border-radius: 50%;
    max-height:clamp(80px,8vw,120px) ;
    max-width: clamp(80px,8vw,120px);
    min-height:clamp(80px,8vw,120px) ;
    min-width: clamp(80px,8vw,120px);
    height: 120px;
    border:4px solid  rgba(37, 42, 59, 1);
    
    display: flex;
   
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
   
}

.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;}
</style>
