<template>
<div>
    <div class="filters">
        <div class="right-filter">
            <DropFilter v-if="tableType=='Crypto'" @choose="(value)=>{coin=value.value}" :items="[{label:'رمزارز',value:''},...coins]"/>     
            <SwitchFilter  @choose="(choice)=>{transType=choice}" :items="[{label:'واریز',value:'DEPSOIT'},{label:'برداشت',value:'WITHDRAW'}]"/>
        </div>
    </div>
<div class="table-container">
        <div class="local-loading" v-if="loading">
            <div class="super-spinner"/>
        </div> 
     <div class="table-title" v-if="tableType=='Rial'">تاریخچه تراکنش های ریالی</div>
    <div class="table-title" v-else>تاریخچه تراکنش های رمزارزی</div>
    <!-- <perfect-scrollbar @ps-y-reach-end="transType=='WITHDRAW'?getWithdrawHistory():getDepositHistory()" ref="scroll"> -->
    <table>
            <tr class="table-header">
                <td class="resp-1">زمان</td>
                <td class="resp-2">نوع تراکنش</td>
                <td v-if="tableType=='Crypto'">نوع ارز</td>
                <td  >مقدار درخواستی<span v-if="tableType=='Rial'">(تومان)</span></td>

                <td v-if="tableType=='Crypto'">آدرس کیف پول</td>
                <td v-if="tableType=='Rial' ">شماره کارت</td>
                <td class="resp-1">شناسه تراکنش</td>
                <td class="resp-1">وضعیت</td>
            </tr> 
            <tr class="table-row" v-for="(transaction,index) in transactions.filter(transaction=>transactionFilter(transaction.relatedCoin))" :key='index'>
                <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                <td class="resp-2" :class="{red:transType=='WITHDRAW',green:transType=='DEPSOIT'}">{{transType=='WITHDRAW'?'برداشت':'واریز'}}</td>
                <td v-if="tableType=='Crypto'">{{$coins[transaction.relatedCoin].persianName}}</td>
                <td > <span dir='ltr'>{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}&nbsp;{{tableType=='Crypto'?$coins[transaction.relatedCoin].code:''}}</span></td>
                <td>{{transaction.destinationWalletAddress || '-'}}</td>
                <td class="resp-1" >{{transaction.hashCode || '-'}}</td>
                
                <td class="resp-1" :style="{color:statusColor(transaction.transactionStatus)}">{{statusLabel(transaction.transactionStatus)}}</td>
            </tr>
        </table> 
      <!-- </perfect-scrollbar> -->
    </div>  
<!-- <div v-if="transType=='DEPSOIT' && currencyType=='RIAL'" class="table-container">
    <div class="table-title">تاریخچه تراکنش های ریالی</div>
    <table  >
        <tr class="table-header">
            <td>نوع تراکنش</td>
            <td> مقدار درخواستی <span class="tag">تومان</span></td>
            <td>شناسه پرداخت</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.payId}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-2"><img src="../../../assets/icons/success.svg"/></td>
            </tr>
        </tbody>
    </table>   
    
</div>
    
<div v-if="transType=='WITHDRAW' && currencyType=='RIAL'" class="table-container">
     <div class="table-title">تاریخچه تراکنش های ریالی</div>
    <table >
        <tr class="table-header">
            <td>نوع تراکنش</td>
            <td>مقدار درخواستی <span class="tag">تومان</span></td>
            <td>شماره شبا</td>
            <td class="resp-1">شماره پیگیری</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.shebaNumber}}</td>
                <td class="resp-1">{{transaction.check}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-2"><img src="../../../assets/icons/success.svg"/></td>
            </tr>
        </tbody>
    </table>    
</div>
   
<div  v-if="transType=='DEPSOIT' &&  currencyType=='CRYPTO'" class="table-container">
    <div class="table-title">تاریخچه تراکنش های رمزارزی</div>
    <table v-if="currencyType=='CRYPTO'" >
        <tr class="table-header">
            <td>نوع تراکنش</td>
            <td >رمزارز</td>
            <td>دریافتی مقصد <span class="tag">({{coin.value}})</span></td>
            <td>کارمزد</td>
            <td class="resp-1">زمان</td>
            <td class="resp-1">شناسه تراکنش</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[transaction.coinName]}}</span>
                    </div>
                </td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.commission}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-1">{{transaction.transId}}</td>
                <td class="resp-2"><img src="../../../assets/icons/success.svg"/></td>
            </tr>
        </tbody>
    </table>  

</div>
    

     
<div  v-if=" transType=='WITHDRAW' &&  currencyType=='CRYPTO'" class="table-container">
    <div class="table-title">تاریخچه تراکنش های رمزارزی</div>
    <table v-if="currencyType=='CRYPTO'" >
        <tr class="table-header">
            <td>نوع تراکنش</td>
            <td >رمزارز</td>
            <td> مقدار درخواستی <span class="tag">تومان</span></td>
            <td>کارمزد</td>
            <td class="resp-1">آدرس کیف پول</td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">شناسه تراکنش</td>
            <td class="resp-2">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(transaction,index) in filterTransactions()" :key="index">
                <td :class="{'depsoit-buy':transaction.transType=='DEPSOIT','withdraw-sell':transaction.transType=='WITHDRAW'}">{{dict[transaction.transType]}}</td>
                <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[transaction.coinName]}}</span>
                    </div>
                </td>
                <td>{{transaction.amount}}</td>
                <td>{{transaction.commission}}</td>
                <td class="resp-1">{{transaction.walletAddress}}</td>
                <td class="resp-1">{{transaction.date}}</td>
                <td class="resp-1">{{transaction.transId}}</td>
                <td class="resp-2"><img src="../../../assets/icons/success.svg"/></td>
            </tr>
        </tbody>
    </table>  

</div> -->
    
</div>
 
</template>

<script>
import SwitchFilter from './SwitchFilter'
import DropFilter from './DropFilter'
import {
    statusLabel,statusColor

} from "@/library/reuseableFunction";

export default {
  name: 'Logins',
  components:{
      SwitchFilter,
      DropFilter,
  },
   computed:{
    transactions(){
      return this.transType=='WITHDRAW'?this.withdraList:this.depositList
    },
    coins(){
              
        return Object.entries(this.$coins).filter(coin=>coin[1].code && coin[0]!='TOMAN').map(coin=>
        {return {label:coin[1].persianName,value:coin[0]}})
    },
    statusLabel,statusColor
  },
  props: {
    tableType:{
      type:String,
      default:function (){
        return 'RIAL'
      }
    },

  },
  methods:{
    transactionFilter(coin){
      return (this.tableType=='Rial' && coin.includes('TOMAN')) || 
      (this.tableType=='Crypto' && !coin.includes('TOMAN') && coin.includes(this.coin) )

    },
    async getWithdrawHistory() {
            const address = 'wallets/withdrawal-requests'
            if (this.page <= this.lastPage) { //for scroll loading
                this.page++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.withdraList = [...this.withdraList, ...res.content]
                    this.lastPage = res.totalPages

                }
                    this.loading=false

            }

        },
        async getDepositHistory() {
            
            const address = 'wallets/deposit'
            if (this.page2 <= this.lastPage2) { //for scroll loading
                this.page2++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page2: this.page2
                    }
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.lastPage2 = res.totalPages
                }
                    this.loading=false


            }
        },
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.modalshow = true
            this.removeid = e.id
        },
        async removeorder(e) {
            
            this.state.loading = true
            await this.$axios.post(`/wallets/withdrawal-request/cancel/${e}`)
            this.modalshow = false
            // this.completedModal = true
            this.$error('حذف با موفقیت انجام شد', '', 'success')
            this.tableContents = []
            this.page = 0
            this.withdraList = []
            this.getWithdrawHistory()
        },

    },
    mounted() {
        this.getWithdrawHistory()
        this.getDepositHistory()
    },
  data(){
    return{
        transType:"DEPSOIT",
      withdraList:[],
      depositList:[],
      loading:false,
      page2:0,
      page:0,
      lastPage2:1,
      lastPage:1,
        dict:{'WITHDRAW':'برداشت','DEPSOIT':'واریز'},
     
        coin:'',

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.left-filter{
    flex-grow: 1;
    max-width: 280px;
}
/* .table-container{
    height: 200px;
    width: 100%;
    position: relative;
    display: flex;
    overflow-y:scroll;
    flex-direction: column;
} */
.mar{
    width:600px;
}
table{
    width: 100%;
}

 @media(max-width:1200px){
   
    .resp-1{
        display: none;
    } 
}
@media(max-width:900px){
    .resp-2{
        display: none;
    } 
}

@media(max-width:600px){
    .resp-3{
        display: none;
    }
}

</style>
