<template>
<div class="card">
    <div class="custom-header">
        شناسایی دو عاملی
    </div>
    <div class="body">
        <p class="text">کد 5 رقمی که به شماره خود ارسال شده است
            را وارد نمایید.</p>
        <input type="number" v-model="otp.code" class="codeinput" />

        <!-- <VerifyCodeInput @change="e=>onChange(e)" @complete="submit()" :num="5" /> -->
        <span class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}} </span>

    </div>
    <button :class="{'confirmBtn':true,'disabled-btn':otp.code.length<6 }" v-if="countDown >0" :disabled="otp.code.length<5" @click="submit()">ثبت</button>
    <button class="confirmBtn" v-if="countDown==0" @click="send()">ارسال مجدد </button>

</div>
</template>

<script>
export default {
    name: 'Finotech',
    components: {},
    data() {
        return {

            countDown: 120,
            error: '',
            num: 5,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username'),
            }
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            // this.reset()
            this.state.loading = true
            const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
            if (res.message === 'Sms sent to customer.') {
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.state.loading = true
            if (this.otp.code.length === 5 || this.loading) {
                this.loading = true
                let [res, ] = await this.$http.get('/finotech/otp', {
                    params: {
                        otp: this.otp.code,
                        email: this.state.userInfo.email
                    }
                })
                this.loading = false
                if (res) {
                    await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
                    this.$router.push({
                        name: 'Authentication'
                    })
                }
            }
            this.state.loading = false
        },
        // paste,
        // reset,
    },
    created() {
        this.countDownTimer()
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        // this.setPlaceholder()
    }
}
</script>

<style scoped>
/* .text {
    font-size: 12px;
    color: #4F4F4F
} */

.disabled-btn {
    opacity: 0.7;
}
.body{
    padding: 24px;
}

input{
    display: block;
    margin-top:4px;
    margin-bottom:12px;
    background: rgba(255, 255, 255, 0.05);
border-radius: 2px;
color:white;
height:clamp(33px,5vw,41px);
width: 100%;
border-style: hidden;
}

.codeinput > input[type="number"] {
  -moz-appearance: textfield;
}


.codeinput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.card {
    text-align: right;
    font-weight: 400;
    /* height: 444px; */
    margin-top: 20px;
    margin-bottom: 20px;
    width: min(432px, 95%);
    color: white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
}

.countdown {
    margin-top: 10px
}
</style>
