<template>
<div>
    <div class="table-header">
        <div class="currency-type">
            <div :class="{active:toman_tether=='TOMAN'}" @click="toman_tether='TOMAN'">تومان</div>
            <div :class="{active:toman_tether=='TETHER'}" @click="toman_tether='TETHER'">تتر</div>
        </div>
        <div class="searchbox">
            <div class="searchbtn"><img src="../../assets/auth/black-search.svg" /></div>
            <input v-model="regex" />
        </div>
    </div>
    <div>
        <table>
            <tr class="head">
                <td class="frst-head" align="right">نوع ارز</td>
                <td align="center">تغییرات</td>
                <td class="resp-3" align="center">قیمت خرید از ما<span class="opac">({{$coins[toman_tether].persianName}})</span></td>
                <td class="resp-2" align="center">قیمت فروش به ما<span class="opac">({{$coins[toman_tether].persianName}})</span></td>
                <td align="center">قیمت لحظه ای<span class="opac">({{$coins[toman_tether].persianName}})</span></td>
                <td class="resp-1" align="center">نمودار</td>
            </tr>
            <tr class="row" v-for="(crypto,index) in filteredCryptos().slice(0,9)" :key="index">
                <td>
                    <div class="title"><img class="icon resp-1" :src="require('../../../public/coins/'+crypto.relatedCoin+'.png')" /><span>{{$coinLabel[crypto.relatedCoin]}}</span><span class="coin-symbol">{{crypto.relatedCoin}}</span></div>
                </td>
                <td align="center"><span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>{{$toLocal(Math.abs(crypto.last24HourChange),2)}}%</span></td>
                <td class="resp-3" align="center"><span>{{$toLocal(crypto.buyPrice,0)}}</span></td>
                <td class="resp-2" align="center"><span>{{$toLocal(crypto.sellPrice,0)}}</span></td>
                <td align="center"><span>{{$toLocal(crypto.lastPrice,0)}}</span></td>
                <td class="resp-1 " align="center">
                    <CoinDataChart :data="crypto.lastWeekData" />
                </td>
            </tr>
        </table>
        <a href="/Markets" @click.prevent="$router.push('/Markets')"><button class="btn">مشاهده همه</button></a>
    </div>
</div>
</template>

<script>
import CoinDataChart from './CoinDataChart'
export default {
    name: 'Markets',
    components: {
        CoinDataChart
    },
    data() {
        return {
            cryptos: [],
            cryptoName: "",
            regex: '',
            toman_tether: "TOMAN",
            loading: false,
            alive: false,
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +

                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +

                '&market_type=TETHER_TOMAN',
            TetherCoin: 'market_type=BITCOIN_TETHER' +
                '&market_type=BITCOIN_CASH_TETHER' +
                '&market_type=ETHEREUM_TETHER' +
                '&market_type=LITE_COIN_TETHER' +
                '&market_type=BINANCE_COIN_BSC_TETHER' +
                '&market_type=MATIC_TETHER' +

                '&market_type=STELLAR_TETHER' +
                '&market_type=TRON_TETHER' +
                '&market_type=RIPPLE_TETHER' +
                '&market_type=DOGE_COIN_TETHER' +
                '&market_type=CELO_TETHER',
        }
    },
    watch: {
        'toman_tether'() {
            this.getPrice();
        }
    },
    methods: {

        async getPrice() {
            let res = await this.$axios.get('/trades/market-info?' + (this.toman_tether == 'TOMAN' ? this.TomanCoin : this.TetherCoin), {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
        filteredCryptos() {
            return this.cryptos.filter(item => {
                if (this.$coinLabel[item.relatedCoin].toLowerCase().includes(this.regex.toLowerCase()) ||
                    item.relatedCoin.toLowerCase().includes(this.regex.toLowerCase()) ||
                    this.$coin2Snake[item.relatedCoin].toLowerCase().includes(this.regex.toLowerCase())) {
                    return item
                }
            })

        },
    },
    mounted() {
        this.alive = true
        // this.beforeLoop()
        this.getPrice()

    },
    beforeDestroy() {
        this.alive = false
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.table-header {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}

.opac {
    font-size: clamp(12px, 1vw, 14px);
    opacity: 0.7;
}

.searchbox {
    display: flex;
    height: 32px;
    max-width: 306px;

    width: 100%;

}

input {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    color: white
}

.searchbtn {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    ;
    border-radius: 2px;
    display: flex;
    padding: 4px;
    align-items: center;

}

.btn {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);

    color: black;
    cursor: pointer;
    width: 100%;
    height: 41px;

}

.currency-type {
    display: flex;
    max-width: 141px;
    align-items: center;
    height: 32px;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    padding: 3px;
    color: white;
}

.currency-type>div {
    flex: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.active {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    border-radius: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    color: black;
}

.change {
    max-width: 48px;
    display: flex;
    direction: ltr;
    justify-content: center;
    border-radius: 2px;
}

.chart {
    vertical-align: inherit;
    max-height: 60px;
}

.title {
    display: flex;
    font-size: clamp(14px, 1.4vw, 16px);
    align-items: center;
    margin-right: 10px;
}

.red {
    background: rgba(214, 43, 43, 0.1);
    color: rgba(214, 43, 43, 1);
    ;

}

.coin-symbol {
    color: #EFEFEF;
    opacity: 0.6;
    font-size: clamp(12px, 1.2vw, 14px);
    margin-right: 4px;

}

.icon {
    margin-left: 8px;
    height: 40px;
    width: 40px;

}

.green {
    background: rgba(11, 135, 16, 0.1);
    color: rgba(11, 135, 16, 1);

}

.frst-head {
    padding-right: clamp(20px, 3vw, 60px)
}

.head {
    background: rgba(37, 67, 130, 0.06);
    color: #EFEFEF;
    height: 41px;
    ;
    font-size: clamp(13px, 1.3vw, 16px);
    font-weight: 400px !important;

}

.row {
    background: #1E232E;
    color: #EFEFEF;
    border-bottom: 2px solid rgba(30, 37, 48, 0.2);
    height: 64px;

}

td {
    font-size: clamp(13px, 1.3vw, 16px);
    font-weight: 400px !important;
}

table {
    font-size: clamp(13px, 1.3vw, 16px);
    font-weight: 400px;
    margin-top: 3px;
    ;
    border-collapse: collapse;
    width: 100%;
}

span {
    font-size: clamp(12px, 1.2, 14px);
}

@media(max-width:900px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
}
</style>
