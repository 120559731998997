<template>
<div :class="{container:true,disabled:disabled}" @click="$emit('click')">
    <div :class="{'input-label':true}">
        <label v-html="label" />
        <input :type="(type=='password'&& !passShow)?'password':'text'" :maxLength="maxLength"  :disabled="disabled" @input="inputHandler()" v-model="temp" />
    </div>

</div>
</template>

<script>
export default {
    name: 'Input',

    props: ['label', 'theme', 'disabled', 'type', 'value','maxLength'],
    watch: {
        'value'() {
            this.temp = this.value
        }
    },
    data() {
        return {
            temp: this.value
        }
    },
    methods: {
        inputHandler() {
            if (this.type == "number") {
                this.temp = this.$toLocal(this.temp)
            } else if (this.type == 'nochar')
                this.temp = this.temp.replace(/[^\d.]|\.\./ig, '')
            console.log(this.type)
            this.$emit('value', this.temp)
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.input-label {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.disabled {
    opacity: 0.6;
}

.input-label>label {
    background: rgba(136, 136, 136, 0.05);
    color: #EFEFEF;

}

.input-label>input {
    background: rgba(136, 136, 136, 0.05);
    color: #EFEFEF;
}

label {
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    line-height: 35px;
    flex-grow: 1;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.container {
    padding: 6px 0px 6px 0px;
    width: 100%;
}

.input-label:focus-within {
    border: 1px solid #F29A39;
}

input {
    size: 1px;
    width: 70px;
    flex-basis: 120px;

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    flex-grow: 30;
    height: 35px;
}

@media(max-width:340px) {
    label {
        width: 100%;
    }
}
</style>
