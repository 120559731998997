<template>
<div class="modal">
    <Modal @close="$emit('modalToggle','')">
        <template #header>
            جزئیات سفارش
        </template>
        <template #body>
            <div class="details">
                <div class="detail">
                    <div> نوع سفارش:</div>
                    <div>{{dict[order.type]}}</div>
                </div>
                <div class="detail">
                    <div> مقدار:</div>
                    <div dir='ltr'>{{order.amount}}</div>
                </div>
                <div class="detail">
                    <div> نوع ارز:</div>
                    <div>{{$coins[order.destinationCoin].persianName}}</div>
                </div>
                <div class="detail">
                    <div> قیمت واحد:</div>
                    <div>{{$toLocal(order.unitPrice,0)}}&nbsp;<span class="tag">تومان</span></div>
                </div>
                <div class="detail">
                    <div> مقدار پرداختی:</div>
                    <div>{{$toLocal(order.totalPrice,0)}}&nbsp;<span class="tag">تومان</span></div>
                </div>
                <!-- <div class="detail"><div> نام صاحب حساب:</div><div>{{order.ownerName}}</div></div>
                    <div class="detail"><div> نام خانوادگی صاحب حساب:</div><div>{{order.ownerFamily}}</div></div>
                    <div class="detail"><div>  آدرس کیف پول:</div><div>{{order.walletAddress}}</div></div> -->

            </div>
        </template>
        <template #footer>
            <CardButton @clicked="$emit('modalToggle','')">تایید</CardButton>

        </template>
    </Modal>
</div>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'OrderDetails',
    components: {
        Modal,
    },

    props: {
        order: Object
    },
    data() {
        return {
            dict: {
                'BUY': 'خرید',
                'SELL': 'فروش',
                'WITHDRAW': 'فروش',
                'DEPSOIT': 'واریز'
            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal {
    max-width: 444px;
}

.details {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.detail {
    display: flex;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #EFEFEF;
}

.tag {
    opacity: 0.7;
}

p {
    font-size: clamp(12px, 1.3vw, 14px);
}
</style>
