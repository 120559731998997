<template>

<Modal @close="$emit('modalToggle','')">
    <template #header>
       خروح
    </template>
    <template #body>
        <div class="modal-text">آیا قصد خروح از حساب خود را دارید؟ </div>
        <div class="btns">
           <button class="no-btn btn"  @click="$emit('modalToggle','')">
                <span class="gradiant-font">خیر</span>
            </button>
            <button class="yes-btn btn" @click="$logout();$emit('modalToggle','')">بله</button>

        </div>

    </template>

</Modal>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'ExitMdodal',
    components: {
        Modal,
    },
    data() {
        return {
        }
    }

}
</script>

<style scoped>
.disabled {
    opacity: 0.6;
}

.btns {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    column-gap: 10px;
}

.btns>button {
    flex-grow: 1;
}

.cancel {
    background: red;
}
.yes-btn {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    border-radius: 2px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 5px;
}
.no-btn {
    background: rgba(255, 255, 255, 0.05);
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: hidden;

    font-style: normal;
    border: 1px solid #FF951F;
    border-radius: 2px;
}

.btn {
    flex-grow: 1;
    border-radius: 8px;
    padding: 6px 15px;
    font-size: clamp(14px, 1.5vw, 16px);
}
</style>
