<template>
  <div class="App">
    <Navbar class="navbar"/>
    <router-view></router-view>
    <LoadingPage v-if="state.loading"/>


  </div>
</template>

<script>
import LoadingPage from './components/tools/LoadingPage'
import Navbar from './components/public/Navbar'
export default {
  name: 'App',
  components: { 
    Navbar,
    LoadingPage

  }
}
</script>

<style>
.App {
 
  min-height: 100vh;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
   background: #1E232E;;
  text-align: center;
}

</style>
