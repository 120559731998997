<template>
<div class="modal-page"> 
<div class="modal">
<Modal @close="$emit('modalToggle','')">
    <template #header>
        شناسایی دوعاملی
    </template>
    <template #body >
        <p class="mytext">کد ۶ رقمی ارسال شده به شماره موبایل <span dir='ltr'>{{mobile}}</span> را وارد نمایید.</p>
        <label-input @value='(value)=>otp.code=value' :theme=1 label="کد ارسالی " />
    </template>
    <template #footer>
         <CardButton  :disabled="!otp.code" @clicked="submit()">تایید کد</CardButton> 
    </template>
</Modal>
</div>
</div>
</template>

<script>
import Modal from './Modal'
export default {
  name: 'CryptoBuy',
  components:{
      Modal,
  },
  props:['mobile'],
  
  data(){
      return{
      otp:{
          code:''
      },      
      payMethod:'VISTA'

      }
  },
    methods : {
    
      onComplete(){
        this.disablebtn=false
        this.submit()
      },
  
         
            async submit() {
                // const a = {
                //     'EMAIL': 'ایمیل',
                //     'SMS': 'پیامک',
                //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                // }
                if (this.otp.code.toString().length === 6) {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=SMS&code=${this.otp.code}`)
                        if(res){
                            // this.reset()
                            this.$emit('modalToggle','')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'ایمیل' + ' تغییر یافت.')
                        }
                   
                }
                
            },

  },
}
</script>

<style scoped>
.modal{
    max-width: 311px;
}
.mytext{
    font-size: clamp(12px,1.4vw,15px);
}
</style>
