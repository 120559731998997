<template>
<div class="levels-page">
    <div class="levels-Card">
        <div class="card-header">
            <img class="cross-icon" src="../../assets/auth/cross.svg" @click="$emit('modalToggle','')" />
            <div class="head">
                <div />سطوح کاربری
                <div />
            </div>
        </div>
        <div class="levels-body">
            <p>با افزایش حجم معاملات خود در سه ماه اخیر میتوانید سطح کاربری خود را افزایش دهید.</p>
            <div class="levels">
                <div :class="{'level-container':true,'middle-level':index<4}" v-for="(level,index) in $accountLevel" :key="index+1">
                    <div class="level">
                        <div :class="{circle:true}" :style="{background:(userLevels[state.userInfo.userAccountLevel] >= index+1)?'linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%)':''}">{{level.wagePercent}}% </div>
                        <div> سطح {{index+1}}</div>
                    </div>
                    <div v-if="index<4" class="line" :style="{background:userLevels[state.userInfo.userAccountLevel] >= index+1?'rgba(242, 154, 57, 1)':'rgba(242, 154, 57, 0.5)'}"></div>
                    <!-- `linear-gradient( to left,rgba(242, 154, 57, 1) 0%,  rgba(242, 154, 57, 1)  ${(value - i*789742)*100/(789742)}%,rgba(242, 154, 57, 0.5) ${(value - i*789742)*100/(789742)}%)` -->
                </div>

            </div>

        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'UserLevels',
    data() {
        return {
                userLevels: {
                'BRONZE': 1,
                'SILVER': 2,
                'GOLD': 3,
                'PLATINUM': 4,
                'SUPERVISOR': 5,
            },
        }
    },
    methods: {
     

    },
    mounted() {
        console.log(this.$accountLevel)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.levels-page {
    z-index: 20;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    padding: 50px 10px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items:center;
}

.levels {
    display: flex;
    width: 100%;
    font-size: clamp(11px, 1vw, 14px);
    align-items: center;
    justify-content: space-around;
}

.cross-icon {
    position: absolute;
    right: 13px;
    cursor: pointer;
}

.card-header {
    display: flex;
    width: 100%;
    color: white;
    position: relative;
    align-items: center;

    justify-content: center;
}

.levels-Card {
    color: white;
    background: #252A3B;
    max-width: 737px;
    font-size: clamp(13px, 1vw, 16px);
    width: 95%;

}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(30px, 3vw, 44px);
    border-radius: 50%;
    height: clamp(30px, 3vw, 44px);
    background: rgba(255, 255, 255, 0.7);
    color: black;

}

.middle-level {
    flex-grow: 1;
}

.line {
    min-height: 4px;
    width: 100%;
    max-width: 115px;
    border-radius: 2px;
}

.levels-body {
    padding: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.level-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-around;

}

.level {
    margin-top: clamp(15px, 1.5vw, 22px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p {
    font-size: clamp(12px, 1vw, 14px);
    color: white;
    text-align: right;
}

.head {
    display: flex;
    column-gap: 4px;
    margin-top: 10px;
    text-align: center;
    font-size: clamp(17px, 1.7vw, 20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.head>div {
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}
</style>
