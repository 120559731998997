<template>
<div>
    <SwitchFilter @choose="(choice)=>{walletType=choice}" :items="[{label:'بازار',value:'P2P'},{label:'صرافی',value:'EXCHANGE'}]" />

    <div class="chart-carousel">

        <Carousel :walletType="walletType" />

        <ApexChart :walletType="walletType" />
    </div>

    <div class="tables">
        <div>
            <Tables />
        </div>

    </div>

</div>
</template>

<script>
import Tables from './Tables'
import ApexChart from './ApexChart'
import Carousel from './Carousel'
import SwitchFilter from '../../tools/SwitchFilter'


export default {
    name: 'Dashboard',
    components: {
        Tables,
        ApexChart,
        Carousel,
        SwitchFilter
        

    },
    methods: {
        filterSender(...filters) {

            return [...filters].map(filter => {
                if (filter.type == 'dropdown')
                    return JSON.parse(JSON.stringify(filter))
                else
                    return filter
            })

        },
    },

    data() {
        return {
            walletType: "P2P",
            cryptoType: {
                label: "رمزارز",
                name: "cryptoType"
            },
            moneyType: {
                label: "نوع پول",
                name: "moneyType"
            },
            commission: {
                label: "کارمزد",
                name: "commission"
            },
            totalPrice: {
                label: "قیمت کل",
                name: "totalPrice"
            },
            date: {
                label: "زمان",
                name: "date"
            },
            condition: {
                label: "وضعیت",
                name: "condition"
            },
            condition2: {
                label: "وضعیت",
                name: "condition2"
            },
            condition3: {
                label: "وضعیت",
                name: "condition3"
            },
            unitPrice: {
                label: "قیمت واحد",
                name: "unitPrice"
            },
            amount: {
                label: "مقدار ",
                name: "amount"
            },
            transType: {
                label: " نوع سفارش",
                name: "transType"
            },
            tradeType: {
                label: " نوع سفارش",
                name: "tradeType"
            },
            marketType: {
                label: " نوع سفارش",
                name: "marketType"
            },
            check: {
                label: " پیگیری",
                name: "check"
            },
            address: {
                label: " آدرس",
                name: "address"
            },
            detail: {
                label: " جزئیات",
                name: "detail"
            },
            requestedAmount: {
                label: " مقدار درخواستی",
                name: "requestedAmount"
            },
            receivedAmount: {
                label: " دریافتی مقصد",
                name: "receivedAmount"
            },
            shebaNumber: {
                label: " شماره شبا",
                name: "shebaNumber"
            },
            payId: {
                label: " شناسه پرداخت",
                name: "payId"
            },
            walletAddress: {
                label: "آدرس کیف پول",
                name: "payId"
            },
            transId: {
                label: "شناسه تراکنش",
                name: "transId"
            },
            browserType: {
                label: " نوع مرورگر",
                name: "browserType"
            },
            platform: {
                label: "پلتفرم ",
                name: "platform"
            },
            device: {
                label: "نوع دستگاه ",
                name: "device"
            },
            ip: {
                label: "آی پی",
                name: "ip"
            },
            checkNumber: {
                label: "شماره پیگیری ",
                name: "checkNumber"
            },

            dataSet: [{
                    cryptoType: "اتریوم",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'false',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "SELL",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: 'eye',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'EZTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },

                {
                    cryptoType: "بیتکوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تومان",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "SELL",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "MARKET",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'EZTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "لایت کوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'false',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "SELL",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'WMPM',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },

                {
                    cryptoType: "لایت کوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'DEPSOIT',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "BUY",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "MARKET",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'EZTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },

                {
                    cryptoType: "بیتکوین",
                    moneyType: "PM",
                    receivedAmount: 0.15,
                    transType: 'DEPSOIT',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "BUY",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "MARKET",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'WMPM',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "دوج کوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'DEPSOIT',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "BUY",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: 'eye',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'AdvTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "اتریوم",
                    moneyType: "PM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "SELL",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: 'eye',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'AdvTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "دوج کوین",
                    moneyType: "PM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تومان",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'false',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "BUY",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'WMPM',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "بیتکوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'DEPSOIT',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "BUY",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'AdvTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "دوج کوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'DEPSOIT',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'false',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "SELL",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "MARKET",
                    detail: 'eye',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'WMPM',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "اتریوم",
                    moneyType: "PM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تتر",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "BUY",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'AdvTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "بیتکوین",
                    moneyType: "WM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تومان",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'false',
                    condition2: 'checking',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "BUY",
                    orderType: "SELL",
                    check: "TX ID",
                    address: "Z850984738948",
                    marketType: "MARKET",
                    detail: '',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'AdvTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },
                {
                    cryptoType: "لایت کوین",
                    moneyType: "PM",
                    receivedAmount: 0.15,
                    transType: 'WITHDRAW',
                    commission: '0.4%',
                    currencyType: "تومان",
                    totalPrice: 353657788,
                    date: "۱۵:۱۱ - ۱۳۸۶/۷۰/۲۱",
                    condition: 'true',
                    condition2: 'sending',
                    condition3: '70%',
                    unitPrice: 323432564,
                    amount: 0.12,
                    tradeType: "SELL",
                    orderType: "BUY",
                    check: "-",
                    address: "Z850984738948",
                    marketType: "LIMIT",
                    detail: 'eye',
                    requestedAmount: 0.2,
                    shebaNumber: "3982937923972",
                    payId: "86347",
                    walletAddress: "0x33a2270bab203640854c0f6d8ef824ccbf7bfe8c",
                    transId: "323323",
                    dealType: 'EZTrade',
                    browserType: "Chrome 91.0.4472.114",
                    platform: "Windows 10.0",
                    device: "Desktop",
                    ip: "۱۸۵.۲۱۲.۲۰۲.۲۷",
                    checkNumber: "86347"
                },

            ],

            //  {title:"بیتکوین",symbol:"BTC",amount:32.7,value:73337997},
            //  {title:"اتریوم",symbol:"ETH",amount:32.7,value:73337997},
            //  {title:"بیتکوین",symbol:"BTC",amount:32.7,value:73337997},
            //  {title:"اتریوم",symbol:"ETH",amount:32.7,value:73337997},
            //  {title:"لایت کوین",symbol:"LTC",amount:32.7,value:73337997},
            //  {title:"لایت کوین",symbol:"LTC",amount:32.7,value:73337997},

            filters: {
                dealType1: {
                    name: 'dealType',
                    title: 'نوع معامله',
                    filterBy: 'EZTrade',
                    type: "dropswitch",

                    conditions: [{
                            name: 'EZTrade',
                            label: 'معامله آسان'
                        },
                        {
                            name: 'AdvTrade',
                            label: 'معامله پیشرفته'
                        },
                        {
                            name: 'WMPM',
                            label: 'معامله وب‌مانی و پرفکت ‌مانی'
                        }
                    ]
                },
                dealType2: {
                    name: 'dealType',
                    title: 'نوع معامله',
                    filterBy: 'EZTrade',
                    type: "dropswitch",

                    conditions: [{
                            name: 'EZTrade',
                            label: 'معامله آسان'
                        },
                        {
                            name: 'AdvTrade',
                            label: 'معامله پیشرفته'
                        },
                        {
                            name: 'WMPM',
                            label: 'معامله وب‌مانی و پرفکت ‌مانی'
                        }
                    ]
                },

            },

        }

    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.opac {
    opacity: 0.7;
}

.tables {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.tables>div {
    height: 325px;

}

.chart-carousel {

    display: flex;
    row-gap: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
    height: 100%;

    width: 100%;

}

.gray {
    color: rgba(30, 37, 48, 0.5);

}
</style>
