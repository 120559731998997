<template>
<div class="modal">

    <Modal @close="$emit('modalToggle','')">
        <template #header>
            واریز {{$coins[relatedCoin].persianName}}
        </template>
        <template #body>
            <div v-if="!selected_net" class="crypto-network">
                <p class="text"> شبکه انتقال ارز را اتنخاب کنید</p>
                <DropFilter v-if="netsList" class="f-1" @choose="(net)=>{cryptoNetworkIndex=netsList.indexOf(net)}" :items="netsList" />
            </div>
            <template v-else>

                <div class="description">
                    <p class="text">آدرس کیف پول شما در زیر قابل مشاهده است. برای واریز بیت‌کوین به این کیف پول، می‌توانید از این آدرس استفاده کنید.</p>
                    <div class="opac-point"><img class="icon" src="../../assets/auth/caution.svg" />
                        <div class="opac">دقت داشته باشید واریز هر نوع ارز دیگر به این آدرس به جز اتریوم موجب از دست رفتن دارایی شما می شود .</div>
                    </div>
                    <div class="opac-point"><img class="icon" src="../../assets/auth/caution.svg" />
                        <div class="opac">توجه کنید که از طریق قرارداد هوشمند (smart contract) ، اتریوم ارسال نکنید. در حال حاضر تنها اتریوم پشتیبانی می شود . از ارسال توکن به این آدرس خودداری کنید.</div>
                    </div>
                    <div class="opac-point"><img class="icon" :style="{opacity:0}" src="../../assets/auth/caution.svg" />
                        <div class="opac">توجه فرمایید در صورتی که از Binance برداشت می کنید حتما Network را ERC20 انتخاب کنید. در غیر این صورت دارایی شما از دست خواهد رفت.</div>
                    </div>
                    <img class="qr-img" :src="'data:image/png;base64,'+qrCode" alt="">
                </div>
                <div class="address-container">
                    <label-input :theme=1 :label="!alertLink?'آدرس کیف پول':'کپی شد'" :value="address" :disabled="true" />
                    <img class="copy-icon"  @click="copyLink()"  src="../../assets/icons/copy.svg" />
                </div>

            </template>
        </template>
        <template #footer>
            <button v-if='!selected_net' @click="selectNet()" class="card-btn">تایید </button>
            <button v-else class="card-btn" @click.prevent="checkWallet" :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''">
                <!-- :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''"" -->
                بررسی واریز
            </button>

        </template>

    </Modal>
</div>
</template>

<script>
import Modal from './Modal'
import DropFilter from '../tools/DropFilter'
export default {
    name: 'CryptoDepsoit',
    components: {
        Modal,
        DropFilter
    },
    computed: {
        netsList() {
            return this.nets.map(net => {
                console.log({
                    label: net.tokenType,
                    value: net.tokenType
                })
                return {
                    label: net.tokenType,
                    value: net.tokenType
                }
            })
        },
    },
    props: {
        relatedCoin: {

            default: function () {
                return ''
            }
        },
        isExchange: {
            default: function () {
                return false
            }
        },

    },

    data() {
        return {
            amount: '',
            address: '',
            qrCode: '',
            tag: '',
            txid: '',
            token: false,
            alertLink: false,
            drop: false,
            selected_net: "",
            txIdIsNeeded: false,
            enable: false,
            coins: [],
            cryptoNetworkIndex: 0,
            nets: []

        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.address);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        closeModale() {
            this.$emit('closeModaleEmit', this.close)
        },
        changeNetwork(e) {
            this.token = e
            this.getQR()
        },
        Copy(e) {
            navigator.clipboard.writeText(e)
            alert('آدرس کپی شد')
        },
        async checkWallet() {
            this.state.loading = true
            // const token = this.token ? 'ERC20' : 'TRC20'
            let query = {
                relatedCoin: this.relatedCoin,
                tokenType: this.selected_net,
                walletType: (this.isExchange ? 'EXCHANGE' : 'P2P')
            }
            if (this.txIdIsNeeded) {
                query.txid = this.txid
            }
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coins[this.relatedCoin].persianName} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        selectNet() {
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.enable = this.nets[this.cryptoNetworkIndex].depositEnable || true
            this.drop = false
            if (this.enable) {
                this.getQR()
            }

        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            const coinData = this.coins.find(coin => coin.coin == this.relatedCoin)
            this.nets = coinData.tokenTypeDetails
            this.wage = coinData.transferFee

        },
        async getQR() {
            this.state.loading = true
            const token = this.selected_net
            let params = {
                relatedCoin: this.relatedCoin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            console.log(params)
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
                params
            }).catch(() => {
                this.closeModale()
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
        }
    },
    mounted() {
        this.getData()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.qr-img {
    margin: auto;
    width: 60%;
    margin-top: 30px;
    margin-bottom: 20px;
}

.copy-icon{
    position: absolute;
    left: 10px;
    top: 14px;
    cursor: pointer;
}
.address-container{
    position: relative;
}
.opac {
    color: #EFEFEF;
    opacity: 0.7;
    font-size: clamp(12px, 1.3vw, 14px);
}

.opac-point {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
}

.text {
    font-size: clamp(12px, 1.4vw, 14px);
    color: #EFEFEF;
}

.description {
    display: flex;
    row-gap: 8px;
    flex-direction: column;

}
</style>
