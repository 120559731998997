<template>
<div class="trade-page" v-if="!loading">
    <div class="switch-filter">
        <SwitchFilter @choose="(choice)=>{baseCoin=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]" />

    </div>
    <div class="trade">
        <Card>
            <template #header>
                <CardHeader>معامله آسان</CardHeader>
            </template>
            <template #body>
                <div class="body">
                    <div class="row1">
                        <div :class="{ buyBtn: dealType=='BUY', btn: true }" v-on:click="dealType='BUY'"> خرید</div>
                        <div :class="{ sellBtn: dealType=='SELL', btn: true }" v-on:click="dealType='SELL'"> فروش</div>
                    </div>

                    <TitlePrice :baseCoin="baseCoin" :cryptos="cryptos" :isBuy="dealType=='BUY'" type="ezDeal" @selectCoin="coin=>setCrypto(coin)" />

                    <div class="row3">
                        <label> موجودی کیف پول: </label>
                        <label class="deposit"> <u>{{credit}}</u>
                            <span class="opac"> {{(dealType=='BUY')?$coins[baseCoin].persianName:$coins[crypto.destCoinSymbol].code}}</span></label>
                    </div>
                    <div class="minmax">
                        <label> حداقل مبلغ سفارش : </label>
                        <label class="deposit"> <u>{{$toLocal(crypto.minAmountExchange,0)}}</u>
                            <span class="opac">{{$coins[crypto.destCoinSymbol].persianName}}</span></label>
                    </div>
                    <div class="minmax">
                        <label> حداکثر مبلغ سفارش: </label>
                        <label class="deposit"> <u>{{$toLocal(crypto.maxAmountExchange,0)}}</u>
                            <span class="opac">{{$coins[crypto.destCoinSymbol].persianName}}</span></label>
                    </div>

                    <div :class="{ row4: true }">
                        <LabelInput :theme=2 :type="'number'" :value="amount" :label="'مقدار'" @value="value=>amountChangeHandler(value)" />
                        <LabelInput :theme=2 :type="'number'" :value="totalPrice" :label="(dealType=='BUY')?'پرداختی':'دریافتی'" @value="value=>totalChangeHandler(value)" />
                    </div>
                    <!-- <SwitchComp v-if="dealType=='BUY'" :theme=2 @choose="(choice)=>payMethod=choice" :choosed="payMethod" :items="[{label:'پرداخت از کیف پول',value:'WALLET'},{label:'پرداخت از درگاه',value:'GATE'}]" /> -->
                    <!-- <SwitchComp v-if="dealType=='SELL'" :theme=2 @choose="(choice)=>wallet=choice" :choosed="wallet" :items="[{label:'فروش از کیف پول ویستا',value:'SITE'},{label:'فروش از کیف پول دیگر',value:'OTHER'}]" /> -->
                    <LabelInput v-if="wallet=='OTHER' && dealType=='SELL'" :theme=2 :type="'text'" :label="'آدرس کیف پول سایت'" :disabled="true" />

                </div>

            </template>
            <template #footer>
                <button :class="{'card-btn':true,disabled:disabled,error:disabled}" :disabled="disabled" :style="{background:(dealType=='BUY')?'#0B8710':'#D62B2B'}" @click="checkModal()">{{disabled?errorTxt:(dealType=='BUY')?'خرید':'فروش' }} {{ disabled?'':$coins[crypto.destCoinSymbol].persianName }}</button>

                <!-- <button v-if="dealType=='BUY' " class="card-btn" :style="{background:'#0b8710'}"  :class="{disabled:disabled}"   
                :disabled="disabled" @click="checkModal()">
                      خرید {{ $coins[crypto.destCoinSymbol].persianName }}
                </button>
                <button v-else  class="card-btn" :style="{background:'#d62b2b'}"  :class="{disabled:disabled}"  
                :disabled="disabled" @click="checkModal()">
                    فروش {{ $coins[crypto.destCoinSymbol].persianName }}
                </button> -->

            </template>

        </Card>
        <TradingView :cryptoSymbol="$coins[crypto.destCoinSymbol].code" :key="crypto.destCoinSymbol" />

    </div>
    <CryptoSell @confirm="sendData()" v-if="modalType=='SELL'" @modalToggle="value=>modalType=value" :order='data' />
    <CryptoBuy @confirm="sendData()" v-if="modalType=='BUY'" @modalToggle="value=>modalType=value" :order="data" />
    <OrderDetails v-if="modalType=='success'" @modalToggle="value=>modalType=value" :order="Object.assign({type:dealType},temptData)" />

</div>
</template>

<script>
import TitlePrice from './components/TitlePrice'
// import SwitchComp from '../../public/SwitchComp'
import SwitchFilter from '../../tools/SwitchFilter.vue'
import TradingView from './components/TradingView.vue'
import CryptoBuy from '../../modals/CryptoBuy'
import CryptoSell from '../../modals/CryptoSell'
import OrderDetails from '../../modals/OrderDetails'

export default {
    components: {
        TradingView,
        SwitchFilter,
        CryptoSell,
        CryptoBuy,
        OrderDetails,
        TitlePrice,
    },
    name: "EzDeal",
    data() {
        return {
            baseCoin: 'TOMAN',
            wallet: "SITE",
            cryptoNetwork: "TRC20",
            depsoit: '',
            dealType: "BUY",
            walletAddress: '',
            cryptos: [],
            crypto: '',
            wallets: '',
            totalPrice: 0,
            coinsDetails: '',
            net: '',
            amount: 0,
            modalType: '',
            data: {},
            payMethod: 'WALLET',
            loading: false,

            cryptoListShow: false,
            regex: "",
            // chartOptions: {
            //     symbol:  this.crypto.destCoinSymbol=='USDT'?"BINANCEUS:USDTUSD":"BINANCE:"+this.crypto.destCoinSymbol+"USDT",
            //     theme: 'dark',
            //     autosize: true,
            //     "interval": "D",
            //     "timezone": "Asia/Tehran",
            //     "style": "1",
            //     "locale": "en",
            //     "toolbar_bg": "#f1f3f6",
            //     "enable_publishing": false,
            //     "hide_top_toolbar": true,
            //     "save_image": false,
            // },

        };
    },
    watch: {
        baseCoin() {
             this.getCoinsPrice()
             this.getWallets()
             this.getCoinsDetails()
        }
    },
    computed: {
        tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return this.$toLocal(temp.credit, 0)
            }
            return 0
        },
        tetherBalance() {
            if (this.wallets['TETHER']) {
                const temp = this.wallets['TETHER']
                return this.$toLocal(temp.credit, this.$decimal[this.crypto.destCoinSymbol])
            }
            return 0
        },

        disabled() {
            return !this.$S2N(this.amount) ||
                (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY') ||
                (this.$S2N(this.dealType == 'BUY' ? this.totalPrice : this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange))
        },
        errorTxt() {
            if ((this.$S2N(this.dealType == 'BUY' ? this.totalPrice : this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.crypto.relatedCoin].code + ' ' + this.crypto.minAmountExchange + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.crypto.relatedCoin].code + ' ' + this.crypto.maxAmountExchange + ' باشد '
            else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY')
                return 'آدرس کیف پول را وارد نمایید'
            else
                return ''

        },
        credit() {
            return (this.dealType == 'BUY') ? (this.baseCoin == 'TOMAN' ? this.tomanBalance : this.tetherBalance) :
                this.$toLocal(this.crypto.credit, this.$decimal[this.crypto.destCoinSymbol])
        },
        sum() {
            return this.unitPrice * this.$S2N(this.amount)
        },
        unitPrice() {
            return this.$S2N((this.dealType == 'BUY') ? this.crypto.buyPrice : this.crypto.sellPrice)
        },
        sumExchangeFee() {
            return this.$S2N(this.crypto.exchangeFee) * this.$S2N(this.amount)

        }

    },
    methods: {
        totalChangeHandler(value) {
            this.totalPrice = value
            this.amount = this.$toLocal(this.$S2N(this.totalPrice) / this.$S2N(this.unitPrice), this.$decimal[this.crypto.destCoinSymbol])
        },
        amountChangeHandler(value) {
            this.amount = value
            this.totalPrice = this.$toLocal(this.$S2N(this.amount) * this.$S2N(this.unitPrice), 0)
        },
        async getCoinsPrice() {
            await this.$axios.get(`coins/exchange-price/${this.baseCoin=='TOMAN'?'usd':'usdt'}`, {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response)

        },
        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },
        async getCoinsDetails() {
            this.state.loading = true
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            if (this.cryptos) {
                if (this.wallets && this.coinsDetails)
                    this.setCrypto(this.cryptos[0])
            }
        },

        setCrypto(coin) {
            this.crypto = coin

            this.crypto.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).minAmountExchange
            this.crypto.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).maxAmountExchange
            this.crypto.credit = this.wallets[this.crypto.destCoinSymbol].credit
            this.crypto.nets = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).tokenTypeDetails
            this.net = this.crypto.nets[0].tokenType
            this.crypto.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).exchangeFee
        },
        checkModal() {
            this.data.amount = this.amount
            this.data.marketType = this.crypto.destCoinSymbol + '_' + (this.baseCoin)
            this.data.destinationCoin = this.crypto.destCoinSymbol
            this.data.tokenType = this.net
            this.data.totalPrice = this.sum
            this.data.unitPrice = this.unitPrice
            this.data.orderType = (this.dealType == 'BUY') ? 'EXCHANGE_BUY' : 'EXCHANGE_SELL'
            if (this.wallet == 'OTHER' && this.dealType == 'BUY')
                this.data.destinationWallet = this.walletAddress
            this.modalType = this.dealType
        },
        async sendData() {
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
            this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice)
            this.modalType = ''
            if (this.dealType == 'BUY') {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )

                this.modalType = 'success'

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.modalType = 'success'

                this.state.loading = false
            }

        },
        async update() {
            let tempCoin
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => tempCoin = response.find(coin => coin.destCoinSymbol == crypto.destCoinSymbol))

            if (tempCoin) {
                this.crypto.buyPrice = tempCoin.buyPrice
                this.crypto.sellPrice = tempCoin.sellPrice

            }

        }
    },
    async mounted() {
        this.state.loading=true
            await this.getCoinsPrice()
            await this.getWallets()
            await this.getCoinsDetails()
        this.state.loading=false


    }

};
</script>

<style scoped>
.switch-filter {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
}

.opac {
    opacity: 0.6;
}

.symbol {
    font-size: 12px;
}

.disabled {
    opacity: 0.5;
}

.deposit {
    display: flex;
    column-gap: 3px;
}

.card-btn {
    color: white;
}

.minmax {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 8px;
    font-size: 12px;
    flex-direction: row;
    width: 100%;
}

.buyBtn {
    background: #0b8710;
    opacity: 1 !important;
    color: white;
}

.trade {
    display: flex;
    width: 100%;
    padding: 48px 0px 48px 0px;
    flex-wrap: wrap;
    row-gap: 10px;

    justify-content: center;
}

.row3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;
    font-size: 12px;
    flex-direction: row;
    width: 100%;
}

.row4 {
    display: flex;
    row-gap: 10px;
    column-gap: 8px;
    margin-top: 4px;
    width: 100%;
}

.privateData {
    margin-top: 12px;
}

.body {
    background: transparent;
    color: #EFEFEF;
    padding: 12px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.sellBtn {
    background: #d62b2b;
    opacity: 1 !important;

    color: white;
}

.row1 {
    color: #EFEFEF;
    display: flex;
    cursor: pointer;
    height: 41px;
    width: 180px;
    background: rgba(37, 67, 130, 0.06);
    border-radius: 2px;
}

.btn {
    display: flex;
    flex: 1;
    opacity: 0.7;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    vertical-align: middle;

    font-style: normal;
}

.card {
    width: min(92%, 444px);
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 2px;
    font-style: normal;
}

.trade>* {
    min-width: 280px;
}

.trade-page {
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}
</style>
