<template>
<div>
    <hooper class="carousel" :infiniteScroll="true" :progress="true" :autoPlay="true" :playSpeed="1500" :rtl="true" :settings="hooperSettings">
        <slide v-for="(crypto,index) in cryptos " :key='index'>
            <div class="container">
                <div class="cryptosprice">
                    <div>
                        <div class="head">
                            <div class="title">
                                <img :src="require('../../../public/coins/'+crypto.relatedCoin+'.png')" />
                                <div>{{crypto.title}}</div>
                                <div class="opac">{{crypto.symbol}}</div>
                            </div>
                            <div :class="{percent:true,negative:crypto.last24HourChange<0,positive:(crypto.last24HourChange>=0)}">
                                <span v-if="crypto.last24HourChange> 0">+</span> <span v-if="crypto.last24HourChange< 0">-</span>
                                {{$toLocal(Math.abs(crypto.last24HourChange),2)}}%
                            </div>
                        </div>
                        <div class="prices">
                            <div>
                                <div>قیمت خرید از ما </div>
                                <div class="price">{{$toLocal(crypto.buyPrice,0)}}
                                    <div class="opac">تومان</div>
                                </div>
                            </div>
                            <div>
                                <div>قیمت فروش به ما</div>
                                <div class="price">{{$toLocal(crypto.sellPrice,0)}}
                                    <div class="opac">تومان</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </slide>

    </hooper>

</div>
</template>

<script>
import {
    Hooper,
    Slide
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'CryptosCarousel',
    components: {
        Hooper,
        Slide

    },
    //   directives: {
    //     swiper: directive
    //   },

    methods: {
        async getPrice() {
            let res = await this.$axios.get('/trades/market-info?' + this.TomanCoin, {
                params: {
                    weeklyDataIncluded: false
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
    },
    mounted() {
        this.alive = true
        // this.beforeLoop()
        this.getPrice()
    },
    data() {
        return {
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +

                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +

                '&market_type=TETHER_TOMAN',
            cryptos: [],
            hooperSettings: {
                itemsToShow: 2,
                centerMode: false,

                breakpoints: {
                    200: {
                        centerMode: true,

                        itemsToShow: 1
                    },
                    630: {
                        centerMode: true,
                        itemsToShow: 2
                    },
                    1000: {

                        itemsToShow: 3,

                    },
                    1400: {
                        itemsToShow: 4,

                    }
                }
            }

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.carousel {
    display: flex;
    align-items: center;
    height: 132px;
    margin-left: -20px;
    z-index: 5;
    box-sizing: border-box;
}

.cryptosprice {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container {
    padding: 0px 10px 0px 10px;
    height: 100%;
}

.opac {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);

}

.negative {
    background: rgba(214, 43, 43, 0.1);
    color: #D62B2B;
}

.title>img {
    width: 40px;

}

.positive {
    background: rgba(11, 135, 16, 0.1);
    color: #0B8710;

}

.percent {
    font-weight: 500;
    font-size: 18px;
    padding-left: 8px;
    direction: ltr;
    padding-right: 8px;
    border-radius: 2px;
}

.price {
    display: flex;
    column-gap: 4px;
    font-weight: 500;

}

.cryptosprice>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    row-gap: 16px;
    background: url('../../assets/trades/chart-line.svg');
    background-repeat: no-repeat;
    background-position: center;

    width: 100%;
    height: 100%;

}

.title {
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    column-gap: 6px;
    align-items: center;
}

.prices {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.prices>div {
    display: flex;
    font-size: 14px;
    font-weight: 400;

    justify-content: space-between;
}

.head {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
}
</style>
