<template>
 <div class="modal-page">
    <div class="modal-comp" @click.self="$emit('close')">
      <div class="modal-header">
        <img class="cross-icon" src="../../assets/auth/cross.svg" @click="$emit('close')"/>
        <slot name="header"></slot>
      </div>
      <div class="modal-body"><slot name="body"></slot></div>
      <slot name="footer"> </slot>
    </div>

 </div>

 


 
</template>

<script>
export default {
  name: 'Modal',
  components:{
  },
  data(){
    return {
      choosed:"VISTA"
    }

  },
 
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.text{
  color: black;
}
.modal-body{
  width: 100%;
  height: 100%;
   text-align: right;
    padding:15px 12px;
}


.modal-comp{
    border-radius: 2px;
  margin-top: 50px;

    color: white;
  max-width: 486px;

    width: 100%;
    background: rgba(37, 42, 59, 1);
    flex-grow: 1;
    /* box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2); */
}

.modal-page{
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  padding: 30px 10px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  display: flex;
  margin: auto;
  justify-content: center;
  
  align-items:flex-start;
}

.modal-body > div{
  width: 100%;
}


.cross-icon{
    position: absolute;
    cursor: pointer;
    right: 10px;
}

.modal-header{
  display: flex;
  width: 100%;
  font-size: clamp(15px,1.5vw,18px);
  color: white;
  border-top-left-radius:2px ;
  border-top-right-radius:2px ;
  min-height: 41px;
  position: relative;
  align-items: center;
  background: rgba(37, 67, 130, 0.06);
  justify-content: center;
}

.cross-icon{
  position: absolute;
  cursor: pointer;
  right: 10px;
}


</style>