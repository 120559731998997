<template>
<div class="card">
    <div class="custom-header">
        <div>ورود</div>
    </div>
    <div class="body font">
        <div class="email">
            <label>آدرس ایمیل</label>
            <input type="text" @input="e=>{data.email=e.target.value;emailError=''}" />
        </div>
        <div class="password">
            <label>رمز عبور</label>
            <div><input :type="(passShow)?'text':'password'" @input="e=>{data.password=e.target.value;}" /><img src="../../../assets/auth/eye.svg" :style="{opacity:(passShow)?'1':'0.5'}" v-on:click="passShow=!passShow" class="eye" /></div>
        </div>
        <a href="/Auth/ForgetPass" @click.prevent="$router.push('/Auth/ForgetPass')"><u class="passforget">فراموشی رمز عبور</u></a>
        <button class="confirmBtn" @click="send()">ورود</button>
        <div class="ya">یا</div>
        <button @click.prevent="googleSso()" class="google-btn font"><img src="../../../assets/auth/google-icon.svg" />ورود با حساب گوگل</button>
        <div class="suggest">حساب کاربری ندارید؟<a href="/Auth/SignUp" @click.prevent="$router.push('/Auth/SignUp')"><u>ثبت نام کنید</u></a></div>
    </div>
</div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            verifyType: {
                EMAIL: 'Email',
                SMS: 'SMS',
                GOOGLE_AUTHENTICATOR: 'GoogleAuth',
            },
            passShow: false,
            data: {
                email: '',
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
            },
        }

    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {

            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {
                console.log(res.message)
                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.$router.push('/Auth/TwoStepVerify/' + this.verifyType[res.baseDTO.towStepAuthenticationType])

            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/Panel/Authentication')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.codeinput>input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.suggest>u {
    cursor: pointer;
}

input {
    display: block;
    margin-top: 4px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    color: white;
    height: clamp(33px, 5vw, 41px);
    width: 100%;

}

input:focus {
    outline: none !important;
    border: 1px solid #f29a39;
}

.google-btn>img {
    margin-left: 6px;
}

.codeinput>input[type="number"] {
    -moz-appearance: textfield;
}

u {
    color: white;
}

.passforget {
    margin-top: 4px;
    font-size: clamp(11px, 1.2vw, 14px);
    font-weight: 400;
    cursor: pointer;
}

.body {
    padding: 24px;
}

.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-style: hidden;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 16px;

    border: 1px solid #F29A39;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 41px;

}

.ya {
    margin-top: 12px;
    width: 100%;
    text-align: center;
}

.card {
    text-align: right;

    font-weight: 400;
    height: 444px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: min(432px, 95%);
    color: white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;

    font-style: normal;
    font-weight: normal;
}

.font {

    font-size: clamp(14px, 1.7vw, 16px);

}

.password {
    margin-top: 12px;
}

.eye {
    position: absolute;
    left: 10px;
    margin-top: 3px;
    cursor: pointer;

}

.password>div {
    display: flex;
    align-items: center;
    position: relative;

}

.suggest>u {
    font-size: clamp(11px, 1.2vw, 14px);
    font-weight: 400;
}

.suggest {
    margin-top: 12px;
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 500;
}
</style>
