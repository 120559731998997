<template>
<div class="modal">

<Modal @close="$emit('modalToggle','')">
    <template #header>
        برداشت {{crypto.title}}
    </template>
    <template #body >
            <div>
                <p>در صورت تمایل به برداشت موجودی کیف پول‌های خود، درخواست خود را اینجا ثبت نمایید.  درخواست‌ها به صورت دستی تایید و سپس پرداخت خواهند شد. کارمزدهای معمول انتقال از مبلغ برداشت کسر خواهد شد. در صورتی کهآدرس مقصد متعلق به کاربران سایت باشد انتقال به صورت مستقیم و آنی صورت می‌گیرد و کارمزد انتقال صفر خواهد بود.</p>
                <p class="point"> کارمزد انتقال در شبکه {{crypto.title}} :  {{crypto.symbol}} 0.005</p>
                <p class="point">حداقل میزان برداشت :  {{crypto.symbol}} 0.001 </p>
                <label-input :theme=1 :label="' میزان برداشت '+' ('+crypto.symbol+')'" :disabled="true"/>
                <div class="detail opac"><div>موجودی  قابل برداشت شما</div> <u>   0.9779797  {{crypto.symbol}}</u></div>
                <label-input :theme=1 label=" آدرس کیف پول مقصد " :disabled="true"/>
                <label-input :theme=1 label="کد 6 رقمی ارسالی به ایمیل "/>
                <u class="detail opac">ارسال مجدد کد</u>
            </div>   
    </template>
    <template #footer>
         <CardButton color="white" background="#D62B2B" @clicked="state.modalType='order-details'">درخواست برداشت </CardButton> 


    </template>
</Modal>
</div>
  
</template>

<script>
import Modal from './Modal'
export default {
  name: 'CryptoWithdarwConf',
  components:{
      Modal,
  },
  props:{
      crypto:Object
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal{
    max-width: 486px;
}
</style>
