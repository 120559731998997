<template>
  <div>
    <div class="head"></div>
    <table>
      <tr class="table-header">
        <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
      </tr>
      <tr class="row" v-for="(transaction, index) in transactions" :key="index">
        <td v-for="(column, index) in columns" :key="index">
            {{ transaction[column.name] }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "HistoryTable",
  props: {
    columns:{type:Object} ,
    transactions: { type: Object },
  },
  data() {
      return {
         
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  background: #fffbf2;
}

.table-header {
  background: rgba(37, 67, 130, 0.06);
  border-radius: 2px;
}


.head{
  background:rgba(242, 154, 57, 0.7);
  color:white;
  width:100%;
}
</style>