<template>
  <div :class="{disabled:disabled}">
        <label>{{label}}</label>
          <div   class="calender">   
            
             <input  class="input calender-input" type="text" :disabled="disabled"  />
              <inline-svg @click="showDatePicker=true" fill="white" class='calender-icon' :src="require('../../assets/auth/calender.svg')"/>
             <date-picker class="calender-comp" 
              custom-input=".calender-input" 
             :show='showDatePicker' 
             v-model="date"
            id="birthday"
            :disabled="disabled"
            :format="$dateFormat['en']"
            :display-format="$dateFormat['fa']"
            :max="$jmoment().format($dateFormat['en'])" ></date-picker></div>
          </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props:{
       label:String,
        value:String,
        disabled:Boolean
  },
   watch: {
            'date'() {
                if (this.date)
                    this.$emit('input',this.date)
            },
            'value'(){
               this.date=this.value
            }
        },
  data(){
    return {
      showDatePicker:false,
      date:this.value

    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.calender-icon{
  position: absolute;
  cursor: pointer;
  left: 7px;
}

.disabled{
  opacity: 0.6;
}
input:focus {
  
  border: 1px solid #f29a39;
}

input {
  height: 41px;
  width: 100%;
  color: white;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  
 
}
.calender{
  
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
 
}

.calender-comp{
  opacity: 1;
  direction: ltr;
  color: black;;

 
  position: absolute;
  height: auto;
  width: 100%;
}
</style>
