<template>

  <div class="card">
    <div class="custom-header" ><div>فراموشی رمز عبور</div></div>
    <div class="body font" >
      <div class="email">
        <label>آدرس ایمیل</label>
        <input type="email" v-model="email" />
      </div>
      <button class="confirmBtn" @click="send()">ارسال کد تایید</button>
    </div>
  </div>
  
</template>

<script>

export default {
  name: "ForgetPass",
  data(){
    return{
        email: '',
        emailError: '',
        verifyType: {
  EMAIL: 'Email',
  SMS: 'SMS',
  GOOGLE_AUTHENTICATOR: 'GoogleAuth',
}
    }
  },
        methods: {
            checkEmail() {
                if (!this.email) {
                    this.emailError = 'ایمیل اجباری';
                } else if (!this.validEmail(this.email)) {
                    this.emailError = 'ایمیل صحیح وارد نمایید';
                } else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            receive(e) {
                this.email = e
                this.checkEmail(e)
            },
            async send() {
                if (this.checkEmail(this.email)) {
                    await this.checkEmail()
                    this.state.loading = true
                    const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                    this.$cookies.set('username', this.email)
                    this.state.userInfo.email = this.email
                    if (res.baseDTO) {
                        this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                        this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                    } else {
                        this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                    }
                    await this.$router.push('/Auth/ChangePass/'+this.verifyType[res.baseDTO.towStepAuthenticationType])
                }
            }
        }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.codeinput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input{
    display: block;
    margin-top:4px;
    margin-bottom:12px;
    background: rgba(255, 255, 255, 0.05);
border-radius: 2px;
color:white;
height:clamp(33px,5vw,41px);
width: 100%;
border-style: hidden;
}

input:focus {
  outline: none !important;
  border: 1px solid #f29a39;
}

.codeinput > input[type="number"] {
  -moz-appearance: textfield;
}


.body{
    padding: 24px;
}

.card {
    text-align: right;
    font-weight: 400;
    height:224px;
    width: min(432px,95%);
    color: white;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
font-style: normal;
font-weight: normal;
}

.font{
   
    font-size: clamp(13px, 1.7vw, 16px); 

}
</style>
