<template>
<div class="commition">
    <div class="head">
        <div />کارمزد
        <div />
    </div>
    <div>
        <h1> شرایط اساسی و اولیه‌ی استفاده کاربران از خدمات </h1>
        <h2> کاربران موظف اند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند. </h2>
        <div class="points">
            <div class="point"> کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود.</div>
            <div class="point"> کارمزد از هر دو سمت معامله کسر می شود.</div>
            <div class="point"> در هنگام ثبت معاملات، میزان کارمزد به شما نمایش داده می شود.</div>
        </div>
        <div class="tradeTable">
            <div class="caution"><img src='../assets/auth/caution.svg' /> لازم به ذکر است کارمزد معاملات جفت ارز (به طور مثال بیت کوین - تتر) به صورت ثابت ۰.۲% در نظر گرفته می شود.</div>

            <table>
                <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">

                
                    <td class="number-font resp" v-if="index === 0">
                        کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                    </td>
                    <td class="number-font resp" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                        {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                        میلیون
                    </td>
                    <td v-else class="resp">
                        از
                        {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                        تا
                        {{tradeAmount($accountLevel[index].tradeAmount)}}
                        میلیون
                    </td>
                    <td class="number-font" align="left">
                        % {{level.wagePercent}}
                    </td>
                </tr>
            </table>
        </div>

    </div>
    <div>
        <h1>کارمزد واریز و برداشت ارز دیجیتال </h1>
        <h2>کاربران موظف اند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.</h2>
        <div class="points">
            <div class="point">کارمزد واریز تمامی دارایی‌های دیجیتال (از جمله بیت‌کوین، اتریوم، لایت‌کوین، ریپل، بیت‌کوین کش، بایننس کوین، تتر و ...) صفر است. </div>
            <div class="point"> کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و ما در آن نقشی نداریم.</div>
        </div>
        <div class="cryptoTable">
            <table>
                <tr>
                    <th>نوع ارز </th>
                    <th align="left">میزان کارمزد</th>
                </tr>
                <tr v-for="(coin,index) in coins" :key="index">
                    <td> {{$coins[coin.coin].persianName}}</td>
                    <td align="left">{{coin.tokenTypeDetails[0].withdrawalFee}}</td>
                </tr>

            </table>
        </div>

    </div>
    <div>
        <h1>کارمزد برداشت ریالی </h1>
        <h2>برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.</h2>
    </div>

</div>
</template>

<script>
export default {
    name: 'Commition',
    data() {
        return {
            coins: []
        }
    },
    computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
    },
    methods: {
        async getData() {
            const res = await this.$axios.get('/coins/details?p2pSupported=true')
            this.coins = res.content
        }
    },
    mounted() {
        this.$getFees()
        this.getData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.commition {

    display: flex;
    height: 100%;
    color: white;
    width: 100%;
    row-gap: 24px;
    flex-direction: column;
    text-align: right;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.point::before {
    content: url('../assets/auth/arrow-left.svg');

}

.commition>div {
    width: 100%;
}

.head {
    display: flex;
    column-gap: 4px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.head>div {
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}

th {

    background: rgba(37, 67, 130, 0.06);
    height: 41px;
    ;
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 400;
    padding-right: 24px;
    padding-left: 24px;

}

.tradeTable {
    display: flex;
    align-items: flex-start;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cryptoTable {
    display: flex;
    justify-content: left;
    max-height: 300px;
    overflow-y: scroll;
}

table {

    width: 471px;
    border-collapse: collapse;
    color: #EFEFEF;
}

td {
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 400;
    background: rgba(136, 136, 136, 0.05);

    padding-right: 24px;
    padding-left: 24px;
    height: 41px;

}

table tr:last-child td:first-child {
    border-bottom-right-radius: 3px;
}

table tr:last-child td:last-child {
    border-bottom-left-radius: 3px;
}

table tr:first-child th:first-child {
    border-top-right-radius: 3px;
}

table tr:first-child th:last-child {
    border-top-left-radius: 3px;
}

tr {
    border-bottom: 1px solid rgba(30, 37, 48, 0.2);
}

.caution {

    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 400;

    display: flex;
    column-gap: 4px;
    align-items: flex-start;
    max-width: 700px;

}

h1 {
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 500;
}

h2 {

    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 400;
}

.points {
    padding-right: 18.5px;
    padding-top: 20px;
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 400;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
</style>
