<template>
<div class="modal">

    <Modal @close="$emit('modalToggle','')">
        <template #header>
            فروش {{$coins[order.destinationCoin].persianName}}
        </template>
        <template #body>
            <div>
                <div class="opac-point">
                    <img class="caution-icon" src='../../assets/auth/caution.svg' />
                    <div >مبلغ نهایی و تایید شده فروش شما بعد از دریافت ارز به کیف پول تومانی شما واریز می شود.</div>
                </div>
                <div class="opac-container">
                    <div class="detail"><div> قیمت واحد:</div><div>{{$toLocal(order.unitPrice,0)}}&nbsp;<span class="tag">{{$coins[order.baseCoin].persianName}}</span></div></div>
                    <div class="detail"><div>مجموع دریافتی شما: </div><div>{{$toLocal(order.unitPrice*order.amount,0)}}&nbsp;<span class="tag">{{$coins[order.baseCoin].persianName}}</span></div></div>
                    <div class="detail"><div>پرداختی شما: </div><div dir='ltr'>{{$toLocal(order.amount,$decimal[order.destinationCoin])}}&nbsp;<span class="tag">{{order.destinationCoin}}</span></div></div>
        
                </div>
            </div>

            <label-input :theme=1 label="شناسه تراکنش " />
        </template>
        <template #footer>
            <CardButton @clicked="$emit('confirm')">ثبت</CardButton>

        </template>

    </Modal>

</div>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'CryptoSell',
    components: {
        Modal,
    },

    props: {
        order: Object
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.modal {
    max-width: 444px;
}

.opac-container {
    background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.opac-point{
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
    font-size: clamp(12px,1.3vw,14px);
    margin-bottom: 8px;
}
.detail {
    display: flex;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #EFEFEF;
}

.tag {
    opacity: 0.7;
}

p {
    font-size: clamp(12px, 1.3vw, 14px);
}
</style>
