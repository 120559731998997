<template>

   <div class="notifictions">
          <div class="message"  v-for="(message,index) in messages" :key="index" >
<div class="message-content" @click="openedIndex=(openedIndex==index)?null:index">
     <div :class="{line:true,seen:message.read}"/>
           <div class="row">
            <div align="right" class="subject" >{{message.title}}</div>
            <div class="opac" >{{message.date}}</div>
            <div align='left' ><img :class="{dropicon:true,dropopen:(openedIndex==index)}" src='../../assets/sidebar/arrowdown.svg'/> </div>       
            </div>      
</div>
<div class="description" v-if="openedIndex==index" :class="{description:true,'open-description':(openedIndex==index)}">
    {{message.content}}
</div>
</div>

   </div>
 
</template>

<script>


export default {
  name: 'Notifcations',
  components:{
   
 

  },
  data(){
      return {
          openedIndex:null,
          messages:[ ]
      }
  },
  methods:{
       async getUnreadMessages() {
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                            page: 1, size: 13,
                        }
                    })
                if(res.message !== 'No Notifications'){
                    this.messages.push(...res.content)
                    this.unreadedCount = res.totalElements
                }
                else{
                    this.messages = []
                    this.unreadedCount = 0
                }
            },
            async readMessage(id,index) {
                this.openedIndex=(this.openedIndex==index)?null:index;
                if (!this.messages[index].read){
                    this.messages[index].read = true
                    await this.$axios.put(`/users/notifications/read/${id}`)
                }
                this.$forceUpdate()
            },
  },
  mounted(){
           this.getUnreadMessages()
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.seen{
       border-left: 4px solid  rgba(242, 154, 57, 0.5)!important;
       
}

.description{
     font-size: clamp(11px,1.3vw,14px);
    font-weight: 400;
    text-align: right;
    padding: 0px;
    /* height: 0px; */
    overflow:hidden;
    transition:  all ease 0.6s;
    padding-left:14px;
    padding-right:14px;
    opacity: 0;
    visibility: hidden;
}
.open-description{
   /* height: 30px; */
   visibility: visible;
   opacity: 1;
  
}

.subject{
    font-size: clamp(13px,1.5vw,16px);
    font-weight: 500;
    /* white-space: nowrap; */
    
}
.line{
     border-left: 4px solid #F29A39;;
     border-bottom-left-radius: 2px;
     display: flex;
    
     border-top-left-radius: 2px;
}

.opac{
    color: rgba(255, 255, 255, 0.7);
    font-size: clamp(12px,1.4vw,16px);
    font-weight: 400;
    
}
.message{
    display: flex;
    flex-direction: column;
    background: rgba(136, 136, 136, 0.05);;
    backdrop-filter: blur(15px);
    border-radius: 2px;
    cursor: pointer;

color: white;
}
.row{
     padding: 12px;
     width: 100%;
     display: grid;
     grid-template-columns:1fr 1fr 1fr ;
     align-items: center;
  
}

.message-content{
    display: flex; 
}
.dropopen{
    transform: rotate(180deg);
}


.dropicon{
   display: flex;
   align-items: center;
  
}


.notifictions{
      display: flex;
    flex-direction: column;
    row-gap: 8px;
} 





@media (max-width:650px){
    .dropicon{
        display: none;
    }

    .row{
        grid-template-columns: 1fr 1fr;
    }

    .opac{
        text-align: left;
    }
}
</style>
