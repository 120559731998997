<template>
<div>
        <div class="head"><div/>مزایای ویستا<div/></div>
    <div class="advantages">
      <div>
               <div class="circle"  v-for="(advantage,index) in advantages.slice(0,2)" :key="index">
            <inline-svg fill="white" class="icon"  :src="require('../../assets/auth/'+advantage.icon+'.svg')"/>
        
            <div>{{advantage.title}}</div>
        </div>

      </div>
      <div>
             <div class="circle" v-for="(advantage,index) in advantages.slice(2,4)" :key="index">
            <inline-svg  class='icon' fill="white" :src="require('../../assets/auth/'+advantage.icon+'.svg')"/>
        
            <div>{{advantage.title}}</div>
        </div>
          
      </div>
       <div>
             <div class="circle" v-for="(advantage,index) in advantages.slice(4,6)" :key="index">
            <inline-svg  fill="white" class='icon' :src="require('../../assets/auth/'+advantage.icon+'.svg')"/>
        
            <div>{{advantage.title}}</div>
        </div>
          
      </div>
   
  </div>

</div>
  
</template>

<script>
export default {
  name: 'Avantages',
  data(){
      return{
          advantages:[
              {title:"امنیت معاملات",icon:"trade-safety"},
              {title:"مدیران حرفه ای",icon:"businessman"},
              {title:"کسب درآمد",icon:"save-money"},
              {title:"کارمزد رقابنی",icon:"commission"},
              {title:"‍‍‍‍‍‍پشتیبانی۲۴ساعته",icon:"hours-support"},
              {title:"کاربری ساده",icon:"ez-use"},
          ]
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advantages{
    display: flex;
    column-gap:30px;
   
    flex-wrap: wrap;
    row-gap:20px;
}
.icon{
    width:clamp(30px,40%,80px);
    height: clamp(30px,40%,80px)
}

.icon:hover{
    fill:rgba(242, 154, 57, 1);
;
}
.circle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: clamp(10px,1vw,16px);
    row-gap: 16px;
    min-width: 80px;
    min-width: 80px;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    width:clamp(120px,20vw,180px);
    height: clamp(120px,20vw,180px);
    border-radius: 50%;
}

.advantages > div{
    display: flex;
    column-gap:30px;
    justify-content: space-around;
    flex: 1;
   
}
.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}
</style>
