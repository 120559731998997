<template>
  <div class="page">
    <div class="head">
      <div/>
      سوالات متداول
      <div/>
    </div>
    <div class="questions">
      <div class="question" v-for="(question, index) in questions" :key="index"
           @click="openedIndex = openedIndex == index ? null : index">
        <div class="row">
          <div class="line"/>
          <div class="subject">
            <div>{{ question.question }}</div>
            <div><img :class="{ dropicon: true, dropopen: openedIndex == index }"
                      src="../assets/sidebar/arrowdown.svg"/></div>
          </div>
        </div>
        <div name="fade">
          <div :class="{description:true,'open-description':openedIndex == index}">
            <p>{{ question.answer }}</p>

          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
export default {
  name: "FAQ",

  data() {
    return {
      openedIndex: null,
      test: false,
      questions: [],
    };
  },
  mounted(){
      this.getFaq()

        },
     methods:{
     async getFaq(){
            this.questions=await this.$axios.get('/faq')
        
     }
  
  }
};
</script>
<style scoped>
.page {
  display: flex;
  row-gap: 24px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 52px;
}

.questions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.head {
  display: flex;
  column-gap: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}

.head > div {
  border-bottom: 2px solid #f29a39;
  width: 16px;
  border-radius: 2px;
}

.description {
  font-size: clamp(11px, 1.3vw, 14px);
  font-weight: 400;
  text-align: right;

  height: 0px;
  overflow: hidden;

  padding-left: 14px;
  padding-right: 14px;
  opacity: 0;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  visibility: hidden;
}

.open-description {
  height: 50px;
  visibility: visible;
  opacity: 1;

}

.question {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  cursor: pointer;

  color: white;
}

.line {
  border-left: 4px solid #f29a39;
  border-bottom-left-radius: 2px;
  display: flex;

  height: 48px;
  border-top-left-radius: 2px;
}

.row {
  display: flex;
  align-items: center;
}

.subject {
  padding-right: 12px;
  padding-left: 12px;
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropopen {
  transform: rotate(180deg);
}

.dropicon {
  display: flex;
  align-items: center;
}

/*.fade-enter-active,
.fade-leave-active {
  transition: transform 0.9s ease;
}

.fade-enter,
.fade-leave-to {
  transform:scaleY(0)
} */
</style>
