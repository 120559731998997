<template>
<div class="card">
    <div class="custom-header">
        <div>ثبت نام</div>
    </div>
    <div class="body font">
        <div class="email">
            <label>آدرس ایمیل</label>
            <input type="text" v-model="data.email" />
        </div>
        <div class="password">
            <label>رمز عبور</label>
            <div><input :type="(passShow)?'text':'password'" v-model="data.password" /><img src="../../../assets/auth/eye.svg" :style="{opacity:(passShow)?'1':'0.5'}" v-on:click="passShow=!passShow" class="eye" /></div>
        </div>
        <div class="inviteCode">
            <label>کد دعوت<p>(اختیاری)</p></label>
            <input type="text" v-model="data.referralCode" />
        </div>
        <div class="terms">
            <div class="agree" v-on:click="agree=!agree"><img src="../../../assets/icons/tik.svg"/><img src="../../../assets/auth/square.svg" /></div><a href="/PrivacyPolicy" @click.prevent="$router.push('/PrivacyPolicy')"><u> قوانین و مقررات </u></a><span>سایت را مطالعه نموده و میپذیرم.</span>
        </div>
        <div class="recaptcha-container">
            <vue-recaptcha  sitekey="6LfrzoAgAAAAAF6WWimMEu6OWv9YW8OWetfALpzW" ref="recaptcha" @verify="recaptcha=true" :loadRecaptchaScript="true"></vue-recaptcha>
        </div>
        <button class="confirmBtn font" :class="{disabled:!data.email || !data.password || !agree || !recaptcha}" :disabled="!data.email || !data.password || !agree || !recaptcha" @click="send()">ثبت نام</button>
        <div class="ya">یا</div>
        <button class="google-btn font" @click.prevent="googleSso()"><img src="../../../assets/auth/google-icon.svg" />ورود با حساب گوگل</button>
        <div class="suggest">حساب کاربری دارید؟<a href="'/Auth/Login'" @click.prevent="$router.push('/Auth/Login')"><u>وارد شوید</u></a></div>
    </div>
</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
    name: "SignUp",
    components: {
        VueRecaptcha

    },
    data() {
        return {
            recaptcha: false,
            otp: false,
            passShow: false,
            agree: false,
            data: {
                email: '',
                password: '',
                referralCode: '',
                //   ipAddress:'',
                deviceType: '',
                osType: '',
                browser: ''
            },
            emailError: '',
            passwordError: ''
        }
    },

    computed: {
        disable() {
            return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0 || !this.agree
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            try {

                await this.checkEmail()
                await this.check2Pass()

                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        await this.$router.push('/Auth/SignUpOtp')
                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push('/Panel/Authentication')
                }
            } catch (e) {
                console.log(e)
            }
        },
        check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passwordError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passwordError = ''
            }
        },
        checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed: ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.codeinput>input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.suggest>u {
    cursor: pointer;
}

.inviteCode>label>p {
    display: inline;
    margin-right: 5px;
    font-weight: 400;
    font-size: clamp(12px, 1.3vw, 14px);

}
.recaptcha-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;

}

.inviteCode {
    margin-top: 12px;
}

.disabled {
    opacity: 0.7;
}

.agree {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

input {
    display: block;
    margin-top: 4px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    color: white;
    height: clamp(33px, 5vw, 41px);
    width: 100%;
    border-style: hidden;
}

.terms u {
    cursor: pointer;
    color: white;
    margin-left: 3px;
    white-space: nowrap;
}

input:focus {
    outline: none !important;
    border: 1px solid #f29a39;
}

.google-btn>img {
    margin-left: 6px;
}

.codeinput>input[type="number"] {
    -moz-appearance: textfield;
}

.passforget {
    margin-top: 4px;
    font-size: clamp(11px, 1.2vw, 14px);
    font-weight: 400;
}

.body {
    padding: 24px;
}

.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-style: hidden;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 16px;

    border: 1px solid #F29A39;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 41px;

}

.ya {
    margin-top: 12px;
    width: 100%;
    text-align: center;
}

.card {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    font-weight: 400;
    height: 633px;
    width: min(432px, 95%);
    color: white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;

    font-style: normal;
    font-weight: normal;
}

.font {

    font-size: clamp(14px, 1.7vw, 16px);

}

.password {
    margin-top: 12px;
}

.eye {
    position: absolute;
    left: 10px;
    margin-top: 3px;
    cursor: pointer;

}

.tik {
    position: absolute;
}

.suggest u {
    font-size: clamp(11px, 1.2vw, 14px);
    font-weight: 400;
    color: white;
}

.suggest {
    margin-top: 12px;
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 500;
}

.terms>span {
    word-break: break-word;
    white-space: nowrap;

}

.terms {

    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: clamp(11px, 1.2vw, 14px);
    font-weight: 400;
}

.password>div {
    display: flex;
    align-items: center;
    position: relative;

}

.body>img {
    margin-top: 12px;
    width: 100%;
}
</style>
