<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        برداشت {{$coins[relatedCoin].persianName}}
    </template>
    <template #body>
        <div v-if="!selected_net" class="crypto-network">
            <p class="text"> شبکه انتقال ارز را اتنخاب کنید</p>
            <DropFilter v-if="netsList" class="f-1" @choose="(net)=>{cryptoNetworkIndex=netsList.indexOf(net)}" :items="netsList" />

        </div>
        <template v-else>
            <div>
                <p class="text">در صورت تمایل به برداشت موجودی کیف پول‌های خود، درخواست خود را اینجا ثبت نمایید. درخواست‌ها به صورت دستی تایید و سپس پرداخت خواهند شد. کارمزدهای معمول انتقال از مبلغ برداشت کسر خواهد شد. در صورتی که آدرس مقصد متعلق به کاربران سایت باشد انتقال به صورت مستقیم و آنی صورت می‌گیرد و کارمزد انتقال صفر خواهد بود.</p>
                <p class="point"> کارمزد انتقال در شبکه {{$coins[relatedCoin].persianName}} : {{wage}}%</p>
                <p class="point">حداقل میزان برداشت : {{$coins[relatedCoin].code}} {{$toLocal(min)}} </p>
                <p class="point">حداکثر میزان برداشت : {{$coins[relatedCoin].code}} {{$toLocal(max)}} </p>
                <label-input @value="value=>amount=value" type='number' :theme=1 :label="' میزان برداشت '+' ('+$coins[relatedCoin].code+')'" />
                <div class="deposit">
                    <div>موجودی قابل برداشت شما</div> <u> {{$toLocal(credit,$decimal[relatedCoin])}} {{$coins[relatedCoin].code}}</u>
                </div>
                <label-input @value="value=>walletAddress=value" :theme=1 label=" آدرس کیف پول مقصد " />
                <p class="error">{{errorHandler}}</p>
                <div :class="{'label-input':true }">
                    <label>کد تایید دوعاملی</label>
                    <div class="input-container">
                        <input maxlength='6' type="text" v-model="code" :disabled="!otpInput" />
                        <button class="email-btn" v-if="otpInput===false" @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
                            ارسال کد تایید
                        </button>
                        <div class="email-btn" v-if="otpInput===true && countDown>0 && counter" :class="{disabled:disabled}">
                            {{Math.floor(countDown/60)}}:{{countDown%60}}
                        </div>
                        <button v-if="otpInput===true && countDown===0 && counter" class="email-btn" @click.prevent="send()">
                            ارسال مجدد
                        </button>
                    </div>

                </div>

            </div>
        </template>
    </template>
    <template #footer>
        <button v-if="!selected_net" class="card-btn" @click="selectNet()">تایید</button>
        <button v-else class="card-btn" :class="{disabled:disabled|| !otpInput || countDown==0}" @click="submitWithdrawRequest()"
         :disabled="disabled || !otpInput || countDown==0">ثبت برداشت </button>

    </template>
</Modal>
</template>

<script>
import DropFilter from '../tools/DropFilter'
import Modal from './Modal'
export default {
    name: 'CryptoWithdarw',
    components: {
        Modal,
        DropFilter
    },
    props: ['relatedCoin', 'credit', 'isExchange'],
    data() {
        return {
            amount: '',
            walletAddress: '',
            local: false,
            token: false,
            transaction: {
                amount: "",
                destinationWalletAddress: "",
                relatedCoin: "",
                tokenType: "",
                code: "",
                walletType: (this.isExchange ? 'EXCHANGE' : 'P2P')
            },
            towStepAuthenticationType: "",
            otpInput: false,
            counter: false,
            timeOut: '',
            countDown: 120,
            code: '',
            selected_net: "",
            txIdIsNeeded: false,
            drop: false,
            enable: false,
            nets: [],
            coins: [],
            wage: 0,
            cryptoNetworkIndex: 0,
            coinData: {},
            min: '',
            max: '',
        }
    },
    computed: {
        netsList() {
            return this.nets.map(net => {
                console.log({
                    label: net.tokenType,
                    value: net.tokenType
                })
                return {
                    label: net.tokenType,
                    value: net.tokenType
                }
            })
        },
        disabled() {
            return !this.enable && this.selected_net ||
                !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
            // ( !this.enable )
            // (!this.selected_net)
        },
        errorHandler() {
            if (!this.enable && this.selected_net)
                return 'در حال حاضر برداشت از شبکه مورد نظر مقدور نیست'
            else if ((this.$S2N(this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.min)))
                return 'مقدار برداشت باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.min + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.max)))
                return 'مقدار برداشت باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.max + ' باشد '
            else
                return ''

        },

    },
    methods: {

        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        selectNet() {
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.transaction.tokenType = this.selected_net
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.wage = this.nets[this.cryptoNetworkIndex].withdrawalFee
            this.max = this.nets[this.cryptoNetworkIndex].maxWithdraw
            this.min = this.nets[this.cryptoNetworkIndex].minWithdraw
            this.enable = this.nets[this.cryptoNetworkIndex].withdrawEnable || true

            this.drop = false

        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.coinData = this.coins.find(coin => coin.coin == this.relatedCoin)
            this.nets = this.coinData.tokenTypeDetails
        },
        async send() {
            this.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        async withdraw() {
            if (!this.disable && !this.state.loading) {
                this.state.loading = true
                this.transaction.amount = this.$S2N(this.amount),
                    this.transaction.destinationWalletAddress = this.walletAddress,
                    this.transaction.relatedCoin = this.relatedCoin
                if ('TETHER' === this.relatedCoin) {
                    this.transaction.tokenType = this.token ? 'ERC20' : 'TRC20'
                }
                const res = await this.$axios.post("/users/send-code")
                this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                this.otpInput = true
                if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                    this.counter = true
                    this.countDownTimer()
                }
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.code = this.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.transaction)
            if (res) {
                this.$emit('modalToggle', '')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged

            }
        },

    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.error {
    font-size: clamp(10px, 1.1vw, 11px);
    color: red;
}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

input {
    background: rgba(136, 136, 136, 0.05);
    color: #EFEFEF;
    height: 35px;

    flex-grow: 1;
    size: 1;
    width: 60px;

}

.label-input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: rgba(136, 136, 136, 0.05);
    margin-top: 6px;
    font-size: 12px;
    height: 35px;
    white-space: nowrap;
    align-items: center;
    column-gap: 10px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}

.email-btn {
    background: rgba(136, 136, 136, 0.05);
    height: 35px;
    padding-left: 10px;
    line-height: 35px;
    font-size: 12px;
    border: 1px goldenrod;
    color: white;

    cursor: pointer;

}

.disabled {
    opacity: 0.7;
}

.deposit {
    display: flex;
    justify-content: space-between;
    color: #EFEFEF;
    font-size: clamp(11px, 1.1vw, 12px);
    padding: 6px 0px;
    opacity: 0.7;
}

.text {
    font-size: clamp(13px, 1.4vw, 14px);
    color: #EFEFEF;
}

.point {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: clamp(11px, 1.1vw, 12px);

}
</style>
