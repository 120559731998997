<template>
<div class="commentsPage">
    <div class="right-sec">
        <div class="head"><div/> نظرات کاربران <div/></div>
        <div class="comments">
      <div class="comment" v-for="(comment,index) in comments" :key="index">
          <div class="comment-header" ><div><img src='../assets/auth/user.svg'/></div>{{comment.author}}</div>
          <div class="comment-content"><div>{{comment.text}}</div><div class="date">{{comment.date}}</div></div>
      </div>
     
    </div>
    </div>
    <div class="coins">
    <img src='../assets/auth/three-coin.svg'/>
    </div>
      
      
</div>
    

</template>
<script>
export default {
  name: 'Commition',
  data(){
      return {
          comments:[
               {author:"آذر ناصری",text:"واریز راحت انجام شد و دسترسی به همه ی بخش ها ساده و سریع بود.",date:"3روز قبل"},
              {author:"زهرا احمدی",text:"از شفافیت تراکنش ها راضی بودم.",date:"7روز قبل"},
              {author:"دریا گودرزی",text:"برداشت راحت انجام شد و دسترسی به همه ی بخش ها عالی بود.",date:"5روز قبل"},
              {author:"ناصر کریمی",text:"سرعت انجام تراکنش عالی بود.",date:"1روز قبل"},
              {author:"شایان صمدی",text:"واریز راحت انجام شد و دسترسی به همه ی بخش ها ساده و سریع بود.",date:"22روز قبل"},
          ]
      }
  }
  
}



</script>
<style scoped>

.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 51px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.comment-header{
    height: 40px;
    display: flex;
     border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    align-items: center;
    column-gap: 8px;
    padding-right:8px;
    background: rgba(37, 67, 130, 0.06);
    color: white;
    font-weight: 500;
     font-size: clamp(12px,1.3vw,14px);
    text-align: right;

}

.comment{
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    min-width: 250px;
    width: 100%;
}
.comment-content{
    display: flex;
    justify-content: space-between;
    padding-left:12px;
    padding-right:12px;
    column-gap: 10px;
    padding-top:16px;
    font-size: clamp(12px,1.2vw,14px);
    font-weight: 400;
    padding-bottom:16px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
   background: rgba(136, 136, 136, 0.05);
    /* BgBlur */
    backdrop-filter: blur(15px);
    color: #EFEFEF;
}

.date{
    color: #EFEFEF;

opacity: 0.7;
    white-space: nowrap;

}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}

.commentsPage{
    text-align: right;
    flex-wrap: wrap;
    color: white;
   width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    column-gap: 10px;
    row-gap: 20px;
    padding-bottom:93px ;
  
}


.right-sec{
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    width: 690px;

  
}
.comments{
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  
}

.coins{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding:20px;
}

img{
    width:100%;
    height: auto;
    display: block;
}
</style>