<template>
<div class="footer">
    <div class="footer-content">
        <div class="footer-text">
            <div class="right-sec">
                <img class="site-icon" src="../../assets/auth/site-logo.png" />
                <p> ویستا تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی‌های دیجیتال می‌باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.</p>
            </div>
            <div class="links">

                <div>
                    <h3>راهنما</h3>
                    <a href="/FAQ" @click.prevent="$router.push('/FAQ')">
                        <p>سوالات متداول</p>
                    </a>
                    <a href="/PrivacyPolicy" @click.prevent="$router.push('/PrivacyPolicy')">
                        <p>قوانین و مقررات</p>
                    </a>
                    <a href="/AboutUs" @click.prevent="$router.push('/AboutUs')">
                        <p>درباره ما</p>
                    </a>
                    <p>بلاگ</p>
                </div>
                <div>
                    <h3>خدمات</h3>
                    <p>خرید ارز دیجیتال</p>
                    <p>فروش ارز دیجیتال</p>
                </div>

                <div>
                    <h3>تماس با ما</h3>
                    <div class="detail">
                        <img :style="{ marginLeft: '7px' }" src="../../assets/footer/location.svg" />
                        {{info.address}}
                    </div>
                    <div class="detail"><img :style="{ marginLeft: '7px' }" src="../../assets/footer/phone.svg" />
                        <div>{{info.number}}</div>
                    </div>
                    <div class="detail"> <img :style="{ marginLeft: '7px' }" src="../../assets/footer/email.svg" />{{info.email}}</div>
                </div>
            </div>
        </div>

        <p>کلیه حقوق این سایت متعلق به شرکت ویستا میباشد</p>
    </div>
</div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            info: {},

        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.right-sec {
    max-width: 310px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 850px;
    justify-content: space-between;

}

.footer {
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    text-align: right;
    display: flex;

    justify-content: space-around;
    color: white;
    z-index: 5;
    background: rgba(37, 67, 130, 0.06);
    min-height: 350px;
    align-items: flex-end;

}

.footer-content>p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}

.site-icon {
    width: 120px;
}

.footer-content {

    display: flex;
    width: 85%;
    padding-top: 10px;
    align-items: stretch;

    flex-direction: column;

    font-style: normal;
    color: white;
}

.footer-text {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    align-items: center;
    padding-bottom: 35px;
    border-bottom: 1px solid #f29a39;
}

p {
    font-size: clamp(12px, 1.3vw, 14px);
    font-weight: 400;
    color: white
}

h3 {
    font-size: clamp(13px, 1.5vw, 16px);
    font-weight: 500
}

.detail {
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    ;
    align-items: center;

}

.circle {
    font-size: clamp(60px, 9vw, 120px);
    color: #C4C4C4;

}
</style>
