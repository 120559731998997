<template>
    <div class="chart-container">
        <VueTradingView class="chart" :options="chartOptions" />
    </div>
</template>

<script>
// import VueTradingView from 'vue-trading-view';
import VueTradingView from 'vue-trading-view/src/vue-trading-view';

export default {
    name: 'TradeView',
    components: {
        VueTradingView,
    },
    props: {
        cryptoSymbol: {
            type: String
        }
    },
    data() {
        return {
            chartOptions: {
                symbol:  `${this.cryptoSymbol=='USDT'?("BINANCEUS:USDTUSD"):("BINANCE:"+this.cryptoSymbol+"USDT")}`,
                theme: 'dark',
                autosize: true,
                "interval": "D",
                "timezone": "Asia/Tehran",
                "style": "1",
                "locale": "en",
                "toolbar_bg": "#f1f3f6",
                "enable_publishing": false,
                "hide_top_toolbar": true,
                "save_image": false,
            },

        }
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    min-height: 376px;

}
.chart-container {
    flex: 2;
    min-width: 255px;

    display: flex;

}
</style>
