<template>

  <div class="card">
    <div class="custom-header " ><div >فراموشی رمز عبور</div></div>
    <div class="body font" >
        <div class="email">
        <label  v-if="verifyType === 'Email'">آدرس ایمیل</label>
        <label  v-if="verifyType === 'GoogleAuth'">آدرس ایمیل</label>
        <label  v-if="verifyType === 'SMS'">شماره موبایل</label>
        <input type="text" :value="data.email" class="disabled" disabled />
      </div>
      <div class="code">
        <label>کد ۶رقمی
            <span v-if="verifyType === 'Email'">ارسال شده به ایمیل</span>
            <span v-if="verifyType === 'GoogleAuth'">شناسایی دوعاملی گوگل</span>
            <span v-if="verifyType === 'SMS'"> ارسال شده به موبایل</span>
        </label>
        <input type="number" v-model="code" class="codeinput" />
      </div>
     <div class="password">
        <label >رمز عبور جدید</label>
        <div><input :type="(passShow)?'text':'password'" v-model="password"  /><img src="../../../assets/auth/eye.svg" :style="{opacity:(passShow)?'1':'0.5'}" v-on:click="passShow=!passShow" class="eye"/></div>
      </div>
      <div class="password">
        <label >تکرار رمز عبور</label>
        <div><input :type="(repassShow)?'text':'password'" v-model="repassword" /><img src="../../../assets/auth/eye.svg" :style="{opacity:(repassShow)?'1':'0.5'}" v-on:click="repassShow=!repassShow" class="eye"/></div>
      </div>
      <button class="confirmBtn" @click="submit">تایید</button>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "ChangePass",
 
    data() {
            return {
                verifyType:this.$route.params.type,
                passShow:false,
                repassShow:false,
                passError: '',
                password: '',
                repassword:'',
                countDown: 120,
                num: 6,
                code: '',
                data: {
                    email: this.state.userInfo.email || this.$cookies.get('username')
                }
            }
        },
        methods: {
            checkPass() {
                if (!this.password) {
                    this.passError = 'رمز اجباری';
                } else {
                    if (this.password.length < 8) {
                        this.passError = 'حداقل 8 کاراکتر';
                    } else if (!this.validPass(this.password)) {
                        this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else if (!this.password===this.repassword) {
                        this.passError = 'مغایرت رمز عبور و تکرار آن';
                    } else {
                        this.passError = ''
                    }
                }
                if (!this.passError.length) {
                    return true;
                }
            },
            setPlaceholder() {
                // for (let i = 0; i < this.num; i++) {
                //     this.$refs['code-input'].$refs['input_' + i][0].placeholder = '-------'
                // }
            },
            countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            onChange(e) {
                this.code = e
            },
            validPass(password) {
                let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
                return re.test(password);
            },
            async submit() {
                if (this.checkPass(this.password)) {
                    this.state.loading = true
                    await this.$axios.post('/users/reset-password', {
                        email: this.$cookies.get('username') || this.state.userInfo.email || '',
                        code: this.code,
                        password: this.password
                    })
                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    await this.$router.push('/Auth/login')
                }
            },
            async send() {
                this.state.loading = true
                this.reset()
                await this.$axios.post('/users/forget-password?email=' + this.$cookies.get('username') || this.state.userInfo.email)
                this.$error('کد جدید ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)

                if (this.countDown === 0) {
                    if (this.state.userInfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR')
                        this.countDown = 180
                    else
                        this.countDown = 120
                    this.countDownTimer()
                }
            },
            reset() {
                this.code = ''
                // for (let i = 0; i < this.num; i++) {
                //     this.$refs['code-input'].values[i] = ''
                //     this.$refs['code-input'].$refs['input_' + i][0].value = ''
                // }
                // this.$refs['code-input'].$refs['input_0'][0].focus()
            }
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted() {
            this.setPlaceholder()
            if (!this.$cookies.isKey('username')) {
                this.$router.push('/Auth/login')
            }
            if (this.pathMatch !== 'google-auth') {
                this.countDownTimer()
            }
        }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.codeinput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.eye{
   position: absolute;
   left:10px;
   margin-bottom:8px;
   cursor: pointer;
  
  
}


.password > div{
   display: flex;
   align-items: center;
   position: relative;
    
}
input{
    display: block;
    margin-top:4px;
    margin-bottom:12px;
    background: rgba(255, 255, 255, 0.05);
border-radius: 2px;
color:white;
height:clamp(33px,5vw,41px);
width: 100%;
border-style: hidden;
}

input:focus {
  outline: none !important;
  border: 1px solid #f29a39;
}

.codeinput > input[type="number"] {
  -moz-appearance: textfield;
}


.body{
    padding: 24px;
}

.card {
    text-align: right;
    font-weight: 400;
    height:306px;
    width: min(432px,95%);
    color: white;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
  
font-style: normal;
font-weight: normal;
}

.font{
   
    font-size: clamp(13px, 1.7vw, 16px); 

}
</style>
