<template>
<div class="card-header">
    <slot></slot>
</div>
  
</template>

<script>
export default {
  name: 'CardHeader',
  props:{
      exit:Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header{
    display: flex;
    width: 100%;
    font-size: clamp(15px,1.5vw,18px);
    color: white;
    border-top-left-radius:2px ;
    border-top-right-radius:2px ;
    min-height: 41px;
    position: relative;
    align-items: center;
    background: rgba(37, 67, 130, 0.06);
    justify-content: center;
}

.cross-icon{
    position: absolute;
    cursor: pointer;
    right: 10px;
}
</style>
