<template>
<div class="order-quenes">

    <div class="grad-table">
        <div class="grad-header">
            <span>زمان</span>
            <span>مقدار({{$coins[tradeFrom].code}})</span>
            <span>قیمت({{dict[tradeTo]}})</span>
        </div>
        <div :style="{color:(trade.orderType.includes('BUY'))?'#0B8710':'#D62B2B'}" class="grad-row" v-for="(trade,index) in lastTrades" :key="index">
            <span>{{$G2J(trade.createdAtDateTime)}}</span>
            <span>{{$toLocal(trade.amount,$decimal[tradeFrom])}}</span>
            <span>{{$toLocal(trade.unitPrice,0)}}</span>
        </div>

    </div>

    <div class="grad-table green-border">
        <div class="grad-header">
            <span>قیمت ({{dict[tradeTo]}})</span>
            <span>مقدار</span>
            <span>مجموع({{dict[tradeTo]}})</span>
        </div>
        <div class="grad-row" v-for="(order,index) in tableContentsBuy" :key="index" :style="{backgroundImage:
            `linear-gradient( to right, rgba(11, 135, 16, 0.1) 0%, rgba(11, 135, 16, 0.1)  ${order.executedPercent}%,
            rgba(136, 136, 136, 0.05) ${order.executedPercent}%)`}">
            <span>{{$toLocal(order.unitPrice,0)}}</span>
            <span>{{$toLocal(order.volume,$decimal[tradeFrom])}}</span>
            <span>{{$toLocal(order.wholePrice,0)}}</span>
        </div>

    </div>

    <div class="grad-table red-border">
        <div class="grad-header">
            <span>قیمت ({{dict[tradeTo]}})</span>
            <span>مقدار</span>
            <span>مجموع({{dict[tradeTo]}})</span>
        </div>
        <div class="grad-row" v-for="(order,index) in  tableContentsSell" :key="index" :style="{backgroundImage:
            `linear-gradient( to left, rgba(214, 43, 43, 0.1)  0%,rgba(214, 43, 43, 0.1) ${order.executedPercent}%,
             rgba(136, 136, 136, 0.05) ${order.executedPercent}%)`}">
            <span>{{$toLocal(order.unitPrice,0)}}</span>
            <span>{{$toLocal(order.volume,$decimal[tradeFrom])}}</span>
            <span>{{$toLocal(order.wholePrice,0)}}</span>
        </div>

    </div>

</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'Tables',
    props: ['tableContentsBuy', 'tableContentsSell'],
    data() {
        return {
            alive: true,
            lastTrades: [],
            dict: {
                "TOMAN": "تومان",
                "TETHER": "تتر"
            },
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    mounted() {

        this.alive = true
        if (this.state.loop) {
            this.loop()
        } else {
            this.getLastTrades()
        }
    },
    beforeDestroy() {
        this.alive = false
    },
    methods: {
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo,
                    size: 18
                }
            })
            this.lastTrades = res.content
            this.loading = false
        },
        async loop() {
            // this.state.loading = true
            this.loading = true

            while (this.alive) {
                await this.getLastTrades()
                this.loading = false
                await this.$sleep(5000)
            }
        },

    }
}
</script>

<style scoped>
.table-header {
    background: rgba(37, 67, 130, 0.06);
    height: 30px;
    opacity: 0.6;
}

.table-row {
    height: 30px;
}

table {
    background: rgba(136, 136, 136, 0.05);
    width: 100%;
    color: white;

}

.table {
    overflow-y: auto;
    max-height: 182px;
}

.grad-row {
    display: grid;

    justify-content: space-between;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: 400;
    height: 30px;
    width: 100%;
}

.grad-header {
    display: grid;
    align-items: center;
    height: 30px;
    position: sticky;
    top: 0;
    grid-template-columns: 1fr 1fr 1fr;
    background: rgba(35,40,51);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-weight: 500;
    width: 100%;
}

.red-border {
    border: 1px solid red;

}

.green-border {
    border: 1px solid green;

}

.grad-table {
    box-sizing: border-box;
    flex-grow: 1;
    white-space: nowrap;
    flex-basis: 230px;
    border-radius: 2px;
    max-height: 255px;
    font-size: clamp(13px, 1vw, 14px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    backdrop-filter: blur(15px);
    background: rgba(136, 136, 136, 0.05);
    color: #EFEFEF;
    /* BgBlur */

    backdrop-filter: blur(15px);

}

.grad-table::-webkit-scrollbar {
    display: none;
}
</style>
