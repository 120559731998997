<template>
<div class="sidebar">
    <div v-for="(link, index) in links" :key="index">
        <div>

            <a :href="link.prefix+link.name" @click.prevent="(link.name=='logout')?showExitModal=true:clickHandler(link,index)" :class='{parentLink:link.subLinks,link:true,active:($route.name==link.name && $route.name!=null)}'>
                <div class="active-line" v-if="(parentCheck(link.subLinks)) || ($route.name==link.name && $route.name!=null)" />
                <div>
                    <img class="icon" v-bind:src="require('../../assets/sidebar/' + link.icon + '.svg')" />
                    {{ link.title }}
                </div>
                <img v-if="link.subLinks" :class="{dropicon:true,dropopen:(openedIndex==index)}" src='../../assets/sidebar/arrowdown.svg' />
            </a>

            <div :class="{sublinks:true,'open-sublinks':(link.subLinks && (openedIndex==index))}" :style="{height:(link.subLinks && (openedIndex==index))?link.subLinks.length*49+'px':'0px'}">
                <a :href="subLink.prefix+subLink.name" :class="{link:true,sublink:true,active:($route.name==subLink.name && $route.name!=null)}" v-for="(subLink,index) in link.subLinks" :key="index" @click.prevent="clickHandler(subLink,index)">
                    <div>
                        <img class="icon" v-bind:src="require('../../assets/sidebar/' + subLink.icon + '.svg')" />
                        {{subLink.title}}
                    </div>
                </a>
            </div>

        </div>
    </div>
    <ExitModal v-if="showExitModal" @modalToggle="showExitModal=false" />

</div>
</template>

<script>
import ExitModal from '../modals/ExitModal'
export default {
    name: "Sidebar",
    components: {
        ExitModal
    },
    methods: {
        clickHandler(link, index) {
            if (link.subLinks)
                this.openedIndex = (this.openedIndex == index) ? null : index
            else
                this.$router.push(link.prefix + link.name)
        },
        parentCheck(links) {
            if (links && links.some(link => this.$route.name == link.name))
                return true
            return false
        }
    },
    data() {
        return {
            showExitModal: false,
            dropOpen: false,
            openedIndex: null,
            links: [{
                    title: "داشبورد",
                    icon: "dashboard",
                    name: "Dashboard",
                    prefix: "/Panel/"
                },
                {
                    title: "حساب کاربری",
                    icon: "account",
                    name: "Authentication",
                    prefix: "/Panel/"
                },
                {
                    title: "معامله",
                    icon: "deal",
                    subLinks: [{
                            title: "معامله پیشرفته",
                            icon: "advdeal",
                            name: "AdvTrade/TOMAN/BITCOIN",
                            prefix: "/Panel/"
                        },
                        {
                            title: "معامله آسان",
                            icon: "ezdeal",
                            name: "EZTrade",
                            prefix: "/Panel/"
                        },
                        {
                            title: "معامله وب مانی",
                            icon: "webmoney",
                            name: "WebTrade",
                            prefix: "/Panel/"
                        },
                        {
                            title: "معامله پرفکت مانی",
                            icon: "perfectmoney",
                            name: "PerfectTrade",
                            prefix: "/Panel/"
                        },
                    ],
                },

                {
                    title: "کیف پول",
                    icon: "wallet",
                    name: "Wallet",
                    prefix: "/Panel/"
                },
                {
                    title: "تاریخچه",
                    icon: "history",
                    subLinks: [{
                            title: "تاریخچه سفارشات",
                            icon: "deal2",
                            name: "Orders",
                            prefix: "/Panel/History/"
                        },
                        {
                            title: "تاریخچه معاملات",
                            icon: "deal2",
                            name: "Trades",
                            prefix: "/Panel/History/"
                        },
                        {
                            title: "تاریخچه تراکنش ریالی",
                            icon: "money",
                            name: "TransactionsRial",
                            prefix: "/Panel/History/"
                        },
                        {
                            title: "تاریخچه تراکنش رمزارزی",
                            icon: "bitcoin",
                            name: 'TransactionsCrypto',
                            prefix: "/Panel/History/"
                        },
                        {
                            title: "لاگین",
                            icon: "login",
                            name: "Logins",
                            prefix: "/Panel/History/"
                        },
                    ],
                },

                {
                    title: "تنظیمات",
                    icon: "gear",
                    name: "Settings",
                    prefix: "/Panel/"
                },
                {
                    title: "اعلانات",
                    icon: "bell",
                    name: "Notifications",
                    prefix: "/Panel/"
                },
                {
                    title: "دعوت از دوستان",
                    icon: "invite",
                    name: "InviteFriends",
                    prefix: "/Panel/"
                },
                {
                    title: "خروج",
                    icon: "logout",
                    name: 'logout',
                    prefix: "/"
                },
            ],
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.sidebar {
    max-height: 700px;
    overflow-y: auto;
    overflow-y: overlay;
    text-align: right;
    position: fixed;

    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
}

.active-line {
    border-left: 4px solid rgba(242, 154, 57, 1);
    border-bottom-left-radius: 2px;
    position: absolute;
    border-top-left-radius: 2px;
    height: 47px;

    ;
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border-bottom: 2px solid rgba(255, 255, 255, 0.05);
    background: #1E232E;
    height: 57px;
    cursor: pointer;
}

.link>div {
    display: flex;
    opacity: 0.7;
    align-items: center;
    column-gap: 4px;
}

.active>div {
    opacity: initial;
}

.sublink>div {
    margin-right: 14px;
}

.open-sublinks {
    opacity: 1 !important;
    visibility: visible !important;
}

.sublinks {
    text-align: center;
    height: 0px;
    visibility: hidden;
    opacity: 0;
    transition: height 1s, opacity 0.4s, visibility 0.4s;
    background: #1e2530;
}

.dropopen {
    transform: rotate(180deg);
}

.dropicon {
    margin-left: 10px;
}

.active {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    /* opacity: 0.7!important; */

}

.sublink {
    background: #1e2530;
    font-size: 14px;
    height: 49px;
    color: white;
    list-style: none;
}

.icon {

    margin-right: 10px;

}
</style>
