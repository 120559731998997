<template>
  <div class="panel">
    <div class="navbar" :sideOpen="sideOpen">
    <!-- <PanelNavbar @showModel="(type)=>{state.modalType=type;state.modalOpen=true}" /> -->

    </div>
    <div class="page">
      <div class="sidebar" :sideOpen="sideOpen" >
        <Sidebar />
      </div>
      <div class="component">
        <EZDeal  v-if="$route.name == 'EZTrade'" />
        <PerfectMoney    v-if="$route.name == 'PerfectTrade'" />
        <WebMoney   v-if="$route.name == 'WebTrade'" />
        <Wallet    v-if="$route.name == 'Wallet'" />
        <Settings   v-if="$route.name == 'Settings'"/>
        <Notifications    v-if="$route.name == 'Notifications'"/>
        <HistoryTable   v-if="$route.name=='RialTransactoins'"/>  
        <InviteFriends   v-if="$route.name=='InviteFriends'"/>
        <Dashboard   v-if="$route.name=='Dashboard'"/>
        <Authentication   v-if="$route.name=='Authentication'" :register="false"/>
        <AdvTrade    v-if="$route.name=='AdvTrade'"/>
        <History   v-if="$route.path.includes('/History')"/>

      </div>
    </div>
   <!-- @close="modalOpen=false" -->
  </div>
</template>
<script>
import Wallet from "./Wallet";
import PerfectMoney from "./trades/PerfectMoney";
import Sidebar from "./Sidebar";
// import PanelNavbar from "./PanelNavbar";
import WebMoney from "./trades/WebMoney";
import EZDeal from "./trades/EZDeal";
import Settings from './Settings/Settings.vue'
import Notifications from './Notifications'
import HistoryTable from './history/HistoryTable'
import InviteFriends from './InviteFriends'
import Dashboard from './Dashboard/Dashboard'
import Authentication from './Authentication/Authentication'
import AdvTrade from './trades/AdvTrade/AdvTrade'
import History from './HistoryCopy/History'

export default {
  name: "Panel",
  components: {
    History,
    Authentication,
    EZDeal,
    InviteFriends,
    PerfectMoney,
    WebMoney,
    AdvTrade,
    HistoryTable,
    Dashboard,
    Wallet,
    Sidebar,
    Notifications,
    Settings
  },
  methods:{
   
  },
  data(){
    return {
      sideOpen:false,
      modalCrypto:Object,
     
    }
  }
};
</script>
<style scoped>

.page {
  display: flex;
  top: 0px;
  position: relative;
  height: calc(100vh - 52px);
  flex-grow: 1;
  width: 100%;

}
.component {
 
  width: 100%;
  position: relative;
  
  padding: 16px;
  background: url('../../assets/auth/panelBg.png');
  background-repeat: no-repeat;
  background-position-y: -60px;
  overflow-x: hidden;
}

.bg{
  top:0 ;
    position: absolute;
    background: url('../../assets/auth/panelBg.png');
    background-repeat: no-repeat;
  
    margin-top: -100px;
    
     min-height:750px ;
    width: 100%;
  
    overflow-x: hidden;
}

.sidebar{
    position: relative;
    width: 220px;
    top: 0px;
    right:0px;
    height: 100%;
}






      /* Extra large devices (large desktops, 1200px and up) */
      @media (max-width: 950px) {  
        .sidebar{
            display: none;
        }
        
      }
        
</style>



