import Router from "vue-router";
import Auth from "../components/auth/Auth";
import Panel from "../components/panel/Panel";
import Vista from "../components/Vista";
import History from "../components/panel/HistoryCopy/History";
import RedirectFromBank from  '../components/public/RedirectFromBank'
import FromGoogle from '../components/auth/FromGoogle.vue'
import Vue from 'vue'

const that = Vue.prototype

const disabledRoutes = [
  'OTC',
  'Exchange',
]
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
   
    {
      path:'/redirect-from-banks',
      name: 'RedirectFromBank',
      component: RedirectFromBank,
    },
    {
      path: '/fromgoogle',
      name: 'FromGoogle',
      component: FromGoogle
  },
    {
      path: "/",
      name: "Vista",
      component: Vista,
      children: [
        {
          path: "/Auth",
          name: "Auth",
          component: Vista,
          children: [
            {
              path: "fino-verifying",
              name: "fino-verifying",
              component: Auth,
            },
            {
              path: "Registeration",
              name: "Registeration",
              component: Auth,
            },
            {
              path: "ChangePass/:type",
              name: "ChangePass",
              component: Auth,
            },
            {
              path: "TwoStepVerify/:type",
              name: "TwoStepVerify",
              props:true,
              component: Auth,
              
            },
            {
              path: "EmailCode",
              name: "EmailCode",
              component: Auth,
            },
            {
              path: "ForgetPass",
              name: "ForgetPass",
              component: Auth,
            },
            {
              path: "Email",
              name: "LoginOtpEmail",
              component: Auth,
            },
            {
              path: "Login",
              name: "Login",
              component: Auth,
            },
            {
              path: "GoogleAuth",
              name: "GoogleAuth",
              component: Auth,
            },
            {
              path: "OtpSMS",
              name: "OtpSMS",
              component: Auth,
            },
            {
              path: "SignUpOtp",
              name: "SignUpOtp",
              component: Auth,
            },
            {
              path: "SignUp",
              name: "SignUp",
              component: Auth,
            },
          ],
        },

        {
          path: "ContactUs",
          name: "ContactUs",
          component: Vista,
        },
        {
          path: "PrivacyPolicy",
          name: "PrivacyPolicy",
          component: Vista,
        },
        {
          path: "Commition",
          name: "Commition",
          component: Vista,
        },
        {
          path: "Comments",
          name: "Comments",
          component: Vista,
        },
        {
          path: "AboutUs",
          name: "AboutUs",
          component: Vista,
        },
        {
          path: "FAQ",
          name: "FAQ",
          component: Vista,
        },
        {
          path: "Markets",
          name: "Markets",
          component: Vista,
        },
        {
          path: "",
          name: "Home",
          component: Vista,
        },
      ],
    },

    {
      path: "/Panel",
      name: "panel",
      component: Panel,
      children: [
        {
          path: "Dashboard",
          name: "Dashboard",
          component: Panel,
        },
        {
          path: "EZTrade",
          name: "EZTrade",
          component: Panel,
        },
        {
          path: "AdvTrade/:currency/:crypto",
          name: "AdvTrade",
          component: Panel,
        },
        {
          path: "PerfectTrade",
          name: "PerfectTrade",
          component: Panel,
        },
        {
          path: "WebTrade",
          name: "WebTrade",
          component: Panel,
        },
        {
          path: "Wallet",
          name: "Wallet",
          component: Panel,
        },
        {
          path: "Settings",
          name: "Settings",
          component: Panel,
        },
      
        {
          path: "Notifications",
          name: "Notifications",
          component: Panel,
        },
        {
          path: "InviteFriends",
          name: "InviteFriends",
          component: Panel,
        },
        {
          path: "Authentication",
          name: "Authentication",
          component: Panel,
        },
        {
          path: "History",
          name: "History",
          component: Panel,
          children: [
            {
              path: "TransactionsRial",
              name: "TransactionsRial",
              component: History,
            },{
              path: "TransactionsCrypto",
              name: "TransactionsCrypto",
              component: History,
            },
             {
              path: "Logins",
              name: "Logins",
              component: History,
            }, 
            {
              path: "Trades",
              name: "Trades",
              component: History,
            }, {
              path: "Orders",
              name: "Orders",
              component: History,
            },
          
          ],
        },
      ],
    },
  ],
});

const forLogin = [
  'History',
  'Dashboard',
  'Profile',
  'AdvTrade',
  'EZTrade',
  'PerfectTrade',
  'WebTrade',
  'TransactionsCrypto',
  'TransactionsRial',
  'Trades',
  'Orders',
  'Wallet',
  'OtcWallets',
  'Settings',
  'Support',
  'Notifications',
  'Orders',
  'Trade',
  'Transaction',

]
const unAuthorUser = [
  'AdvTrade',
  'EZTrade',
  'PerfectTrade',
  'WebTrade',
  'TransactionsCrypto',
  'TransactionsRial',
  'Trades',
  'Orders',
  'Dashboard',
  'Wallet',
  'OtcWallets',
  
]
const otp = [
  'TwoStep',
]
const auth = [
  'Login',
  'SignUp',
  'ForgetPassword',
]
router.beforeEach(async (to, from, next) => {

  that.state.loading = true

  if(!that.state.coins){
    await that.$getCoins()
  }

  if (that.state.token) {

      if (!that.state.gotten) {
        that.state.loading = true
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true
      if(that.state.userInfo.supervisor && disabledRoutes.includes(to.name)){
        that.$error('','با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است')
          next(from)
      }
       if (from.name === 'Blog') {
          await window.open(to.fullPath, '_self')
      } else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'Home'})
      } else if (to.name === 'Trade') {
        if(!to.params.tradeFrom || !to.params.tradeTo){
          next('/profile/trade/TOMAN/BITCOIN')
        }else{
          try {
            that.state.loading = true
            await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
            next()
        } catch (e) {
            if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                if (otp.includes(from.name)) {
                    next({name: 'HomePage'})
                } else {
                    await that.$error('بازار غیرفعال است')
                    next(from)
                }
            }
        }
        }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'Account') {
                  next({name: 'Account'})
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/Panel/Authentication')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
          next({name: 'Login'})
      else {
          next()
      }
  }
  that.state.loading = false
})

export default router;
