<template>
<div class="modal">
    <Modal @close="$emit('modalToggle','')">
        <template #header>
            برداشت ریالی
        </template>
        <template #body>
            <div>
                <div class="depsoit">موجودی:&nbsp;{{$toLocal(cash,0)}}&nbsp;تومان</div>
                <p>درصورت تمایل به برداشت موجودی کیف های خود در خواست خود را در اینجا ثبت نمایید.</p>
                <p class="opac-text"> درخواست های تسویه ریالی در روزهای تعطیل به دلیل تعطیلی بانک ها و عدم فعالیت سیستم پایا و ساتنا انجام نمی شود . درخواست های تسویه بالای 100 میلیون تومان به دلیل محدودیت های بانکی حداکثر تا یک روز آینده کاری از زمان ثبت درخواست انجام خواهدشد. درخواست های ثبت شده تا ساعت 12:30، در ساعت 16 تسویه خواهد شد. درخواست های برداشت ریالی بعد از ساعت 12:30 تا ساعت 22، در روز کاری بعد در ساعت 4 الی 11 صبح تسویه خواهد شد.️ درخواست های بعد از ساعت 22 تا 9 صبح، در ساعت 13 ، تسویه خواهد شد. درخواست های ثبت شده به حساب مقصد بانک آینده معمولا در زمان سریع تری انجام می شود . </p>
                <label-input @value='value=>amount=value' type="number" :theme=1 label="مبلغ برداشت(تومان)" />
                <div class="card-select">
                    <LabelInput @value='value=>regex=value' :value="walletAddress" label=" شماره کارت" @click="show=!show" :disabled="true" />
                    <div class="cards-drop" v-if="show" :style="{height:cardFilter.length*30+'px'}">
                        <div class="card-row" v-for="(card,index) in cardFilter" :key="index" @click="chooseCard(card.cardNumber)">
                            {{card.cardNumber}}
                        </div>
                    </div>
                </div>
                  <p class="error">{{errorHandler}}</p>

                <div :class="{'label-input':true }">
                    <label>کد تایید دوعاملی</label>
                    <div class="input-container">
                        <input maxlength='6' type="text" v-model="TwoStep_code" :disabled="!otpInput"/>
                        <button class="email-btn" v-if="otpInput===false" @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
                            ارسال کد تایید
                        </button>
                        <div class="email-btn" v-if="otpInput===true && countDown>0 && counter" :class="{disabled:disabled}">
                            {{Math.floor(countDown/60)}}:{{countDown%60}}
                        </div>
                        <button v-if="otpInput===true && countDown===0 && counter" class="email-btn" @click.prevent="send()">
                            ارسال مجدد
                        </button>
                    </div>

                </div>
            </div>
        </template>
        <template #footer>
            <button class="card-btn" :class="{disabled:!otpInput || countDown==0 || TwoStep_code.length<6}" :disabled="!otpInput || countDown==0 || TwoStep_code.length<6" @click="submitWithdrawRequest()">ثبت</button>
        </template>
    </Modal>
</div>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'RialWithdraw',
    components: {
        Modal,
    },
    props:{
          cash: {
            default: function () {
                return 0
            }
        },
    },
    computed: {
        cardFilter() {
            if (this.banks)
                return this.banks.filter(card => card.cardNumber.replace('-', '').startsWith(this.regex))
            else
                return []
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
            // ( !this.enable )
            // (!this.selected_net)
        },
        errorHandler() {
            if ((this.$S2N(this.amount) > this.$S2N(this.cash)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.min)))
                return 'مقدار برداشت باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.min + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.max)))
                return 'مقدار برداشت باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.max + ' باشد '
            else
                return ''

        },

    },
    data() {
        return {
            banksOptions: false,
            unit: "تومان",
            amount: '',
            banks: [],
            regex: '',
            walletAddress: '',
            TwoStep_code: '',
            towStepAuthenticationType: '',
            transaction: {
                code: '',
                amount: '',
                relatedCoin: 'TOMAN',
                destinationWalletAddress: ''
            },
            show: false,
            countDown: 120,
            otpInput: false,

        }
    },

    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show = false
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.amount = this.$S2N(this.amount)
            this.transaction.destinationWalletAddress = this.walletAddress
            this.transaction.code = this.TwoStep_code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.$emit('modalToggle', '')
            }
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
            const coinsResponse = await this.$axios.get('/coins/details')
            this.coins = coinsResponse.content
            this.coinData = this.coins.find(coin => coin.coin == 'TOMAN')
            this.wage = this.coinData.tokenTypeDetails[0].withdrawalFee
            this.max = this.coinData.tokenTypeDetails[0].maxWithdraw
            this.min = this.coinData.tokenTypeDetails[0].minWithdraw

        },
        async withdraw() {
            if (!this.disabled && !this.state.loading) {
                const res = await this.$axios.post("/users/send-code")
                if (res.message === "code sent successfully.") {
                    this.otpInput = true
                    this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                    if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                        this.counter = true
                        this.countDownTimer()
                    }
                }
            }

        },

        async send() {
            this.TwoStep_code = ''

            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    async mounted() {
        await this.getData()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.error {
    font-size: clamp(12px, 1.3vw, 14px);
    color: red;
}
.disabled{
    opacity: 0.5;
}
.depsoit{
    font-size: clamp(13px,1.3vw,14px);
}
input {
    background: rgba(136, 136, 136, 0.05);
    color: #EFEFEF;
      height: 35px;

    flex-grow: 1;
    size: 1;
    width: 60px;
   

}
.label-input {
     border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: rgba(136, 136, 136, 0.05);
    margin-top: 6px;
      font-size: 12px;
      height: 35px;
      white-space: nowrap;
     align-items: center;
     column-gap: 10px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}
.email-btn {
    background: rgba(136, 136, 136, 0.05);
    height: 35px;
    padding-left: 10px;
    line-height: 35px;
    font-size: 12px;
    border: 1px goldenrod;
    color: white;

    cursor: pointer;

}
.modal {
    max-width: 486px;
}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

.card-select {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
}

.card-row {
    padding: 5px 10px;

}

.cards-drop {
    margin-top: -6px;
    width: 100%;
    cursor: pointer;
    background: rgba(136, 136, 136, 0.05);

    color: #EFEFEF;
    font-size: clamp(13px, 1.4vw, 14px);
    max-height: 50px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
}
</style>
