<template>
<div class="deal" >

    <div class="switchMarket">
       
        <div :class="{activeMarket:marketType=='LIMIT'}" @click="marketType='LIMIT'">حد</div>
        <div :class="{activeMarket:marketType=='MARKET'}" @click="marketType='MARKET'">بازار</div>
    </div>
    <div class="card" >
        <div :class="{cardbody:true,'red-border':!isBuy,'green-border':isBuy}">
            <div class="dealswitch">
                <div :class="{buyswitch:isBuy}" @click="isBuy=true;getWallet()">خرید</div>
                <div :class="{sellswitch:!isBuy}" @click="isBuy=false;getWallet()">فروش</div>
            </div>

            <div class="depsoit">
                <div>موجودی:</div>
                <div :dir='isBuy?"rtl":"ltr"'><u>{{credit}}</u> {{(isBuy)?dict[tradeTo]:crypto.relatedCoin}}</div>

            </div>
            <div class="inputFields">
                <div>
                    <LabelInput  :theme=2 :type="'number'" :value="$toLocal(unitPrice,0)" @value="value=>unitPrice=value" :label="'قیمت('+dict[tradeTo]+')'" :disabled="marketType=='MARKET'" />
                </div>
                <div>
                    <LabelInput :theme=2 :type="'number'" :value="$toLocal(amount,$decimal[tradeFrom])" @value="value=>amount=value" :label="'مقدار'" />
                </div>
                <div :class="{slider:true,'slider-padding':marketType=='MARKET'}">
                    <div class="slider-bg" :style="{backgroundImage:`linear-gradient( to right, rgba(242, 154, 57, 1) 0%,  rgba(242, 154, 57, 1)  ${amountPercent}%, rgba(37, 42, 59, 0.1) ${amountPercent}%)`}">
                    </div>
                    <input min="0" max="100" v-model="amountPercent" type="range" />
                    <div class="steps-dote">
                        <div class="step1" v-for="(step , index) in steps" :key="index">
                            <div class="dot" :style="{minWidth:step.size,height:step.size,}" @click="setValue(index)">
                                <span v-if="index==2">{{amountPercent}}%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <LabelInput :theme=2 :type="'number'" :label="'مقدار پرداختی'" :disabled="true" :value="$toLocal($S2N(unitPrice)*$S2N(amount),0)" />
                </div>

                <div class="date" v-if="marketType=='LIMIT'">
                    <label>تاریخ انقضا<span>(اختیاری)</span> </label>
                    <input class="calender-input" />
                    <inline-svg fill="white" class='calender-icon' :src="require('../../../../assets/auth/calender.svg')" />
                    <date-picker class="calender-comp" custom-input=".calender-input" :type="'datetime'" v-model="date" :format="$dateFormat['en']" :display-format="$dateFormat['faDateTime']" :min="$jmoment().format($dateFormat['en'])"></date-picker>
                </div>
            </div>
            <div v-if="isBuy">
                <div>بالاترین پیشنهاد خرید:</div><span :style="{color:'#0B8710'}">
                    <u>{{$toLocal(shopInfo.maxBuy,0)}}</u>&nbsp;{{dict[tradeTo]}}</span>
            </div>
            <div v-if="!isBuy">
                <div>پایین ترین پیشنهاد فروش:</div><span :style="{color:'#D62B2B'}"><u>{{$toLocal(shopInfo.minSell,0)}}</u>&nbsp;{{dict[tradeTo]}}</span>
            </div>
            <div :class="{'comision-padding':!limit_market}">
                <div> کارمزد:</div><u>{{fee}}%</u>
            </div>
        </div>
        <button :class="{btn:true,disabled:disabled,error:disabled}" :disabled="disabled" :style="{background:(isBuy)?'#0B8710':'#D62B2B'}" @click="showModal()">{{disabled?errorTxt:(isBuy)?'خرید':'فروش'}}</button>

    </div>
    <CryptoSell @confirm="accept()" v-if="modalType=='SELL'" @modalToggle="value=>modalType=value" :order='data' />
    <CryptoBuy @confirm="accept()" v-if="modalType=='BUY'" @modalToggle="value=>modalType=value" :order="data" />

</div>
</template>

<script>
import CryptoBuy from '../../../modals/CryptoBuy'
import CryptoSell from '../../../modals/CryptoSell'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'TradeComp',
    props: ['crypto', 'shopInfo'],
    components: {
        CryptoSell,
        CryptoBuy
    },
    data() {
        return {
            amountPercent: 0,
            isBuy: true,
            credit:'',
            limit_market: true,
            wallets: [],
            marketType: "LIMIT",
            modalType: '',
            amount: 0,
            unitPrice: 0,
            coinsDetails: [],
            maxAmount: 0,
            minAmount: 0,
            date: '',
            dict: {
                "TETHER": 'تتر',
                'TOMAN': 'تومان'
            },
            steps: [{
                    value: 0,
                    size: "9px"
                },
                {
                    value: 25,
                    size: "11px"
                },
                {
                    value: 50,
                    size: "20px"
                },
                {
                    value: 75,
                    size: "15px"
                },
                {
                    value: 100,
                    size: "17px"
                },
            ],

        }
    },
    watch: {

        'amountPercent': function () {
            if (this.marketType == 'MARKET' && this.isBuy)
                this.getAmountFromServer()
            else if (this.percentSliderActive) {
                this.localCalculate()
            }
        },
        'amount': function () {
            if (this.marketType == 'MARKET') {
                this.getPriceFromServer()
                console.log(this.unitPrice)
            }
        }
    },

    computed: {
        tradeTo,
        tradeFrom,
        sum() {
            return this.$toLocal(this.$S2N(this.amount) * this.$S2N(this.unitPrice), 0)
        },

        // credit() {
        //     return (this.isBuy) ? this.tomanTetherCredit :
        //         ((this.wallets[this.$coin2Snake[this.crypto.relatedCoin]]) ? 
        //         this.$toLocal(this.wallets[this.$coin2Snake[this.crypto.relatedCoin]].credit,
        //         this.$decimal[this.$coin2Snake[this.crypto.relatedCoin]]) : 0)
        // },
        tomanTetherCredit() {
            return (this.wallets[tradeTo]) ? this.$toLocal(this.wallets[tradeTo].credit, this.$decimal[tradeTo]) : 0
        },
        fee() {
            return this.$S2N(this.state.userInfo.fee)
        },




















        
        disabled() {
            return !this.state.userInfo.supervisor? (!this.$S2N(this.amount) ||
                !this.unitPrice ||
                ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.maxAmount)) ||
                (this.$S2N(this.amount) < this.$S2N(this.minAmount))) : 
//supervisor

              !this.$S2N(this.amount) || !this.unitPrice ||  (this.$S2N(this.amount) > this.$S2N(this.maxAmount)) ||
              (this.$S2N(this.amount) < this.$S2N(this.minAmount)) 
        },
        errorTxt() {
            if ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit) && !this.state.userInfo.supervisor )
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.minAmount)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.tradeFrom].code + ' ' + this.minAmount + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.maxAmount)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.tradeFrom].code + ' ' + this.maxAmount + ' باشد '
            else if (!this.percentSliderActive && this.amountPercent > 0)
                return ' برای استفاده از اسلایدر مقدار ابتدا قیمت را وارد کنید'
            else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.unitPrice)
                return 'قیمت واحد را وارد کنید'
            else
                return ''

        },



        percentSliderActive() {
            return !(this.marketType == 'LIMIT' && this.isBuy && !this.unitPrice)
        }

    },
    methods: {
        async getWallet() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
                console.log(res[this.$route.params.crypto].credit)
                console.log(res[this.$route.params.currency].credit)


                this.isBuy ? this.credit = res[this.$route.params.currency].credit : this.credit =  res[this.$route.params.crypto].credit

            }
            // this.loading = false
        },
        showModal() {
            if (!this.disabled) {
                this.data = {
                    amount: this.$S2N(this.amount),
                    unitPrice: this.$S2N(this.unitPrice),
                    baseCoin: this.tradeTo,
                    destinationCoin: this.tradeFrom,
                    type: this.isBuy ? 'BUY' : 'SELL',
                    isLimit: this.marketType === 'LIMIT'
                }
                if (this.date)
                    this.data.date = this.date
                console.log(this.isBuy)
                this.modalType = this.isBuy ? 'BUY' : 'SELL'

            }

        },

        setValue(index) {
            this.amountPercent = this.steps[index].value
        },

        inputRange() {
            this.$emit('rangeValue', this.val)
        },
        async getCoinsDetails() {
            let coin
            this.state.loading = true
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            if (this.crypto) {
                coin = this.coinsDetails.find(crypto => crypto.coin == this.tradeFrom)
                this.maxAmount = coin.maxAmount
                this.minAmount = coin.minAmount
            }
        },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            if (amount) {
                const res = await this.$axios.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: `MARKET_${this.isBuy?'BUY':'SELL'}`
                    }
                })
                let price = res.baseDTO.wholePrice;
                this.totalPrice = this.$toLocal(price.toFixed(0));
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount).toFixed(0));
                console.log(this.unitPrice)
                this.value = this.$S2N(this.totalPrice) / this.cash
            }
        },
        localCalculate() {
            let tempAmount = (this.amountPercent / 100) * this.$S2N(this.credit)
            if (this.isBuy)
                this.amount = tempAmount / this.$S2N(this.unitPrice)

            else
                this.amount = tempAmount
        },
        async getAmountFromServer() {
            let e = this.amountPercent / 100 * this.$S2N(this.credit)
            if (e) {
                this.getAmount = true
                const res = await this.$axios('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                }).catch(() => {})
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[this.tradeFrom])
                    // this.calculate()
                }
            }
        },
        calculate() {
            if (this.marketType == 'LIMIT' && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (this.marketType == 'MARKET') {
                this.serverCalculate()
            }
        },
        async serverCalculate() {
            this.calculating = true
            const a = this.amount
            const temp = ++this.count
            await this.$sleep(500)

            if (a) {
                while (this.alive && a && a === this.amount && temp === this.count) {
                    try {
                        await this.getPriceFromServer()
                        this.calculating = false
                        await this.$sleep(5000)
                    } catch (e) {
                        this.calculating = false
                        break
                    }
                }
            } else {
                this.calculating = false
            }

        },

        filterOrders(filter) {
            return this.orders.filter(order => {
                if (order.tradeType == filter)
                    return order
            })
        },

        isLoaded() {
            return this.wallets && this.crypto && this.tableContents && this.shopInfo.minSell
        },
        async accept() {
            this.loading = true;
            let orderDetail;
            let marketType = this.tradeFrom + '_' + this.tradeTo
            if (this.data.isLimit) {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `LIMITED_${this.isBuy ? 'BUY' : 'SELL'}`,
                    expiresAt: this.date || null,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.sum),
                }
            } else {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.data.amount),
                    orderType: 'MARKET_' + this.data.type,

                }
                if (this.data.date) {
                    orderDetail.date = this.data.date
                }
            }
            try {
                await this.$axios.post(`/orders/${this.data.type.toLowerCase()}`, orderDetail)
                this.loading = false
                this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
                this.$emit('modalToggle', 'SUCCESS')
            } catch (error) {
                this.loading = false
                this.$emit('modalToggle', '')
                if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                    await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                }
            }
            this.modalType = ''
        },
    },
    mounted() {
        this.getWallet()
        this.getCoinsDetails()

    }
}
</script>

<style scoped>
.disabled {
    opacity: 0.5;
}

.dealswitch {
    display: flex;
    height: 32px;
    max-width: 113px;
    cursor: pointer;
    background: rgba(37, 67, 130, 0.06);
    padding: 1px;

    border-radius: 2px;
}

.date>label {
    font-size: clamp(13px, 1.3vw, 14px);
}

.dealswitch>div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}

.error {
    color: red;
    font-size: 12px;
}

.inputFields {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.btn {
    width: 100%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    color: white;
    height: 41px;
    backdrop-filter: blur(15px);
}

.red-border {
    border: 1px solid red;

}

.green-border {
    border: 1px solid green;

}

.slidecontainer {
    width: 100%;
    position: relative;
}

.calender-input {
    color: white;
    direction: 'ltr';

}

.calender-comp {
    color: black;
}

.calender-icon {
    position: absolute;
    cursor: pointer;
    bottom: 3px;
    left: 7px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    color: #EFEFEF;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    min-height: 346px;
    width: 100%;

}

.date {
    display: flex;
    align-items: center;
    position: relative;
}

.sellswitch {
    background: #D62B2B;
    color: white;
    border-radius: 2px;
}

.buyswitch {
    background: #0B8710;
    border-radius: 2px;
    color: white;
}

.cardbody {
    display: flex;
    flex-direction: column;
    background: #1E232E;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    row-gap: 4px;
    flex-grow: 1;
    padding: 8px;
}

.cardbody>div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.switchMarket {
    background: rgba(136, 136, 136, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;
    display: flex;
    height: 30px;
    padding: 2px;
    width: 158px;
    cursor: pointer;

}

.switchMarket>div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EFEFEF;
    opacity: 0.7;

}

input:not([type="range"]) {
    background: rgba(136, 136, 136, 0.05);
    height: 35px;

    flex-grow: 1;
}

.slider-padding {
    padding: 28px 0px 28px 0px;
}

.inputFields>div {
    display: flex;
    flex-wrap: wrap;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;

}

.inputFields>div>label {
    background: rgba(136, 136, 136, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;

    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
    text-align: center;
}

.activeMarket {
    border-radius: 2px;
    opacity: 1 !important;
}

.step1 {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dot {

    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}

.comision-padding {
    padding-bottom: 2.5px;
}

.slider {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    direction: ltr;
}

input[type='range'] {
    z-index: 1;
    height: 20px;
    width: 100%;
    opacity: 0;
    cursor: pointer;

}

.slider-bg {
    position: absolute;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    width: 100%;
    height: 10px;

}

.steps-dote {
    position: absolute;

    margin-left: 2px;
    width: 100%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
}
</style>
