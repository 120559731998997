<template>
<div class="market" :key='tradeFrom+tradeTo'>
    <div v-if="buyAlert" class="success success-buy">خرید شما با موفقیت انجام شد</div>
    <div v-if="sellAlert" class="success success-sell">درخواست فروش شما شما با موفقیت ثبت شد.</div>
    <TradeBar :cryptos="cryptos" :crypto="crypto" />

    <div class="trade">
        <TradeComp :crypto="crypto" :shopInfo="shopInfo" />
        <TradingView :cryptoSymbol="$coins[tradeFrom].code" :key="tradeFrom" />
    </div>
    <Tables :tableContentsSell="tableContentsSell" :tableContentsBuy="tableContentsBuy" />
    <History />

</div>
</template>

<script>
import TradeBar from './TradeBar.vue'
import TradingView from '../components/TradingView'
import History from './History'
import TradeComp from './TradeComp'
import Tables from './Tables'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";

export default {

    name: 'AdvTrade',
    components: {
        TradeBar,
        TradingView,
        History,
        TradeComp,
        Tables

    },
    computed: {
        tradeTo,
        tradeFrom
    },

    methods: {

        async getCoins() {
            // let tempCoin
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res.filter(crypto => crypto.marketType.includes(this.tradeTo))
                this.crypto = this.cryptos.find(crypto => crypto.marketType.includes(this.tradeFrom))

            }
        },

        async getMarketDepth() {

            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo
                }
            })
            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a, b) => {
                    a.unitPrice > b.unitPrice
                })
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a, b) => {
                    return a.unitPrice > b.unitPrice
                })
                this.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },
        setAlert(alert) {
            if (alert == "buy") {
                this.buyAlert = true
                setTimeout(() => {
                    this.buyAlert = false
                }, 2000)
            }

            if (alert == "sell") {
                this.sellAlert = true
                setTimeout(() => {
                    this.sellAlert = false
                }, 2000)
            }

        },

        filteredOrders(type) {
            return this.orders.filter(order => {
                if (order.buy == type) {
                    return order
                }
            })
        },

    },
    beforeDestroy() {
        this.loop1.stop()
        this.loop2.stop()
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop1 = new Loop(this.getCoins, 5000, 'realPrice' + (this.tradeFrom))
            this.loop2 = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '_' + this.tradeTo)

            this.loop1.start()
            this.loop2.start()
        } else {
            this.getCoins()
            this.getMarketDepth()

        }

    },
    data() {
        return {

            tableContentsSell: [],
            tableContentsBuy: [],
            coinsDetails: [],
            shopInfo: {
                minSell: 0,
                maxBuy: 0
            },
            sellAlert: false,
            buyAlert: false,
            dropShow: false,
            choosed: "TETHER",
            dict: {
                "TETHER": 'تتر',
                'TOMAN': 'تومان'
            },
            baseCrypto: "TETHER",
            val: 0,

            cryptos: [],
            crypto: {},
            deal: {
                title: "تومان",
                value: "TOMAN"
            },

            value: 0,

            buy_sell: true,
            //   crypto:{symbol:"BTC",value:3.231,price:354343441,minSell:354343441,maxBuy:354343441  },
            amount: 0,
            price: 0,
            orderType: true,

        }
    }

}
</script>

 Add "scoped" attribute to limit CSS to this component only 

<style scoped>
.success {
    position: absolute;
    top: 0px;
    font-size: clamp(14px, 1.5vw, 16px);
    z-index: 10;
    right: -16px;
}

.success-buy {
    background: #0B8710;
    padding: 10px 36px 10px 36px;
    color: white;
}

.success-sell {
    background: #F29A39;
    padding: 10px;
    color: black;

}

.opac {
    opacity: 0.7;
    font-size: clamp(10px, 1vw, 12px);
}

.symbol {
    opacity: 0.7;
    font-weight: normal;

}

.change {
    padding: 2px 8px 2px 8px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
}

.green {
    background: rgba(11, 135, 16, 0.1);
    color: rgba(11, 135, 16, 1);

}

.red {
    background: rgba(214, 43, 43, 0.1);
    color: rgba(214, 43, 43, 1);
    ;

}

.condition {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 5px;

    flex-grow: 1;
    width: 100%;
    max-width: 60px;
}

.chart {

    width: 100%;
    min-height: 250px;
    height: 100%;

}

.chart-container {
    flex: 2;
    min-width: 255px;

    display: flex;

}

.deal {
    flex: 1;
    min-width: 255px;

}

.order-quenes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.order-table {
    font-size: clamp(13px, 1.2vw, 14px);
    display: flex;
    white-space: nowrap;
    flex-direction: column;

}

.disabled {
    opacity: 0.6;
}

.trade {
    display: flex;
    row-gap: 10px;
    justify-content: center;
    column-gap: 8px;
    flex-wrap: wrap;

}

.market {
    display: flex;
    position: relative;

    flex-direction: column;
    row-gap: 8px;
}

@media(max-width: 520px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width: 430px) {
    .resp-2 {
        display: none;
    }
}
</style>
