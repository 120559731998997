<template>
<div class="history">
    <div class="page-header" :style="{display:'none'}">
        <div class="table-title">{{currentTable.label}}</div>
        <div class="table-filter">
            <DropFilter class="sf" @choose="value=>currentTable=value" :items="pages" />
        </div>
    </div>
    <Logins v-if="$route.name=='Logins'" />
    <Transactions v-if="$route.name=='TransactionsRial'" :key="$route.name" :tableType="'Rial'" />
    <Transactions v-if="$route.name=='TransactionsCrypto'" :key="$route.name" :tableType="'Crypto'" />
    <Orders v-if="$route.name=='Orders'" />
    <Trades v-if="$route.name=='Trades'" />
</div>
</template>

<script>
import DropFilter from './DropFilter'
import Logins from './Logins'
import Transactions from './Transactions'
import Orders from './Orders'
import Trades from './Trades'
export default {
    name: 'History',
    props: {

    },
    data() {
        return {
            pages: [{
                    label: 'تاریخچه ورود به حساب کاربری',
                    value: 'logins'
                },
                {
                    label: 'واریز و برداشت',
                    value: 'transactions'
                },
                {
                    label: 'سفارشات',
                    value: 'orders'
                },
                {
                    label: 'معاملات',
                    value: 'trades'
                },

            ],
            currentTable: {
                label: 'تاریخچه ورود به حساب کاربری',
                value: 'logins'
            },

        }
    },
    components: {
        Logins,
        DropFilter,
        Transactions,
        Orders,
        Trades
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
::v-deep .table-container {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 400px !important;
    max-height: 400px !important;
    overflow-y: scroll;
}

.sf {
    z-index: 23 !important;
}

.page-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: space-between;
    /*color: var(--history-page-header)!important;*/
}

::v-deep .table-filter {
    max-width: 220px;
    flex-grow: 1;
    /*color: var(--history-page-header) !important;*/

}

::v-deep .table-title {
    color: #EFEFEF;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    z-index: 2;
    align-items: center;
    background: rgba(35, 42, 56);
    border-radius: 2px;
    font-size: clamp(14px, 1.5vw, 16px);
    backdrop-filter: blur(15px);
    height: 41px;

}

::v-deep .filters {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    row-gap: 10px;

    flex-wrap: wrap;
}

::v-deep .right-filter {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    column-gap: 10px;

}

.history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}

::v-deep .coin-title {
    display: flex;
    align-items: center;
    column-gap: 2px;
    justify-content: center;
    font-family: 'YekanBold';
}

::v-deep .table-header>td:last-child {
    text-align: left;
    padding-left: 24px;
}

::v-deep .table-header>td:first-child {
    text-align: right;
    padding-right: 24px;
}

::v-deep .table-row>td:last-child {
    text-align: left;
    padding-left: 24px;

}

::v-deep .table-row>td:first-child {
    text-align: right;
    padding-right: 24px;

}

::v-deep .coin-icon {
    width: 16px;
    height: 16px;
    padding-bottom: 3px;
}

::v-deep .tradeType {
    /*color: var(--history-blue);*/
}

::v-deep .table-row {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #EFEFEF;
    background: rgba(136, 136, 136, 0.05);
    border-bottom: 1px solid #5a5959;
}

::v-deep table {
    width: 100%;
    border-collapse: collapse;
    backdrop-filter: blur(15px);
}

::v-deep .table-header {
    background: rgba(35, 42, 56);
    border-radius: 2px;
    position: sticky;
    top:41px;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #EFEFEF;

}

::v-deep tbody {}

::v-deep .tag {
    font-size: clamp(11px, 1.1vw, 13px);
    font-family: 'IRANSansLight';

}

::v-deep td {
    padding: 10px;
}

::v-deep .red {
    color: #D62B2B;

}

::v-deep .green {
    color: #0B8710;

}

::v-deep .status {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
